import React from 'react';
import RecipeItem from '../../components/RecipeItem';
import { useListRecipeLibraryQuery, useDeleteRecipeMutation, useRemoveRecipeFromQueueMutation, useAddRecipeToQueueMutation } from 'src/store/apiSlice';

export default function RecipeList({ additionalActions = [] }) {
    console.log('additionalActions', additionalActions);

    const { data: recipes, error, isLoading } = useListRecipeLibraryQuery();
    const [deleteRecipe] = useDeleteRecipeMutation();
    const [removeRecipeFromQueue] = useRemoveRecipeFromQueueMutation();
    const [addRecipeToQueue] = useAddRecipeToQueueMutation();

    const handleDelete = async (recipeId) => {
        const recipeIds = { saved_recipe_ids: [recipeId] };
        await deleteRecipe(recipeIds);
    };

    const handleQueueToggle = async (recipeId, queued) => {
        if (queued) {
            await removeRecipeFromQueue(recipeId);
        } else {
            await addRecipeToQueue(recipeId);
        }
    };

    const renderRecipeSection = (recipes, title) => {
        if (recipes.length === 0) return null;

        return (
            <>
                {title && <h3 className="text-2xl font-bold mb-4">{title}</h3>}
                <div className="flex flex-wrap gap-2">
                    {recipes.map(recipe => (
                        <RecipeItem
                            key={recipe.id}
                            recipe={recipe}
                            actions={[
                                ...additionalActions.map(action => ({
                                    actionText: action.text,
                                    actionHandler: () => action.handler(recipe)
                                })),
                                { 
                                    actionText: recipe.queued ? 'Unqueue' : 'Queue', 
                                    actionHandler: () => handleQueueToggle(recipe.id, recipe.queued) 
                                },
                                { actionText: 'Delete from library', actionHandler: () => handleDelete(recipe.id) }
                            ]}
                        />
                    ))}
                </div>
            </>
        );
    };

    return (
        <div className="w-full">
            {isLoading && <span className="loading loading-dots loading-lg"/>}
    
            {recipes && recipes.length > 0 ? (
                <>
                    {recipes.some(recipe => recipe.queued) ? (
                        <>
                            {renderRecipeSection(recipes.filter(recipe => recipe.queued), 'Queued Recipes')}
                            {renderRecipeSection(recipes.filter(recipe => !recipe.queued), 'All Other Recipes')}
                        </>
                    ) : (
                        renderRecipeSection(recipes, null)
                    )}
                </>
            ) : (
                <div className="text-md">No recipes in your library yet</div>
            )}
        </div>
    );
}