import React from 'react';
import { MealIdeaInput } from '../mealPlan/MealIdeaInput';

const Prototype = () => {
  const handleRecipeSelection = (recipeData, searchText) => {
    console.log('Recipe selected:', { recipeData, searchText });
  };

  const handleBuildSelection = (buildData, searchText) => {
    console.log('Build recipe:', { buildData, searchText });
  };

  const handleSearchMore = (searchData, searchText) => {
    console.log('Search more:', { searchData, searchText });
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <MealIdeaInput 
        placeholder="Enter a recipe idea..."
        recipeHandler={handleRecipeSelection}
        buildHandler={handleBuildSelection}
        searchHandler={handleSearchMore}
      />
    </div>
  );
};

export default Prototype; 