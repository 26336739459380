import React from 'react';
import { useParams } from 'react-router-dom';
import GroceryList from './GroceryList';
import { Toaster } from '../../components/Sonner';

function GroceryListPage() {
    const { groceryListId } = useParams();

    return (
        <div className="max-w-[800px] justify-center mx-auto px-4">
            <GroceryList groceryListId={groceryListId} />
            <Toaster className="max-h-[200px]" visibleToasts={15} />
        </div>
    );
}

export default GroceryListPage; 