import RecipeItem from '../../components/RecipeItem';
import { Button } from '../../components/Button';
import { useAddRecipeToMealMutation } from '../../store/apiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { 
    openExternalSearchRecipeSheet,
    openCreateGeneratedRecipeSheet,
    setExternalSearchRecipeSearchTerm
} from './mealPlanSlice';
import { closeRecipeSheet } from '../../store/globalSlice';

const HydratedRecipeIdea = ({ idea, mealId, className }) => {
    const [addRecipeToMeal] = useAddRecipeToMealMutation();
    const dispatch = useDispatch();
    const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);

    const hasGeneratedRecipeId = idea?.recipe?.generated_recipe_id != null;

    const handleAddToMeal = async (recipe) => {
        try {
            dispatch(closeRecipeSheet());
            await addRecipeToMeal({
                meal_id: mealId,
                recipe_id: recipe.recipe_id,
                multiplier: 1,
                recipe: recipe
            });
        } catch (error) {
            console.error('Failed to add recipe to meal:', error);
        }
    };

    const handleOpenExternalSearch = () => {
        dispatch(closeRecipeSheet());
        dispatch(setExternalSearchRecipeSearchTerm(idea.value));
        dispatch(openExternalSearchRecipeSheet());
    };

    const handleOpenGeneratedRecipe = () => {
        if (!hasGeneratedRecipeId) return;
        
        dispatch(openCreateGeneratedRecipeSheet({
            allowSelectionOfMeal: false,
            selectedMealId: mealId,
            generatedRecipeId: idea.recipe.generated_recipe_id
        }));
    };

    const recipeActions = [
        {
            actionText: 'Add to meal',
            actionHandler: () => handleAddToMeal(idea.recipe)
        }
    ];

    const sheetActions = [
        {
            label: 'Add to meal',
            onClick: () => handleAddToMeal(idea.recipe)
        },
        ...(hasGeneratedRecipeId ? [{
            label: 'Edit recipe',
            onClick: handleOpenGeneratedRecipe
        }] : []),
        ...(idea.function === 'find_internet_recipe' ? [{
            label: 'Search for other recipes',
            onClick: handleOpenExternalSearch
        }] : []),
        ...(idea.function === 'build_simple_ai_recipe' ? [{
            label: `Search for ${idea.value}`,
            onClick: handleOpenExternalSearch
        }] : [])
    ];

    return (
        <RecipeItem
            key={idea.recipe.recipe_id}
            recipe={idea.recipe}
            suppressProcessing={true}
            actions={recipeActions}
            sheetActions={sheetActions}
            showExternalLink={!hasGeneratedRecipeId}
            candidateRecipe={true}
            className={`${className || ''}`}
        />
    );
};

export default HydratedRecipeIdea; 