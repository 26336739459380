import React, { useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { useCreateRecipeMutation } from '../../store/apiSlice';
import { setGeneratedRecipeId, setRecipeChatStreaming, setCurrentPrompt } from './recipeGenerationSlice';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';
import { apiSlice } from '../../store/apiSlice';
import { v4 as uuidv4 } from 'uuid';
import { ArrowUp, Square } from 'lucide-react';

const RecipeGenerationChat = ({ onRecipeIdChange }) => {
    const dispatch = useDispatch();
    const [createRecipe, { isLoading }] = useCreateRecipeMutation();
    const navigate = useNavigate();
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const recipeChatStreaming = useSelector((state) => state.recipeGeneration.recipeChatStreaming);
    const currentPrompt = useSelector((state) => state.recipeGeneration.currentPrompt);
    const wsService = useRef(null);

    const initiateWebSocketConnection = useCallback((query, recipeId) => {
        if (wsService.current) {
            wsService.current.disconnect();
            wsService.current = null;
        }

        wsService.current = new WebSocketGenerationService({
            endpoint: 'stream_recipe_generation',
            payload: {
                user_query: query,
                generated_recipe_id: recipeId,
            },
            onOpen: () => {
                dispatch(setRecipeChatStreaming(true));
            },
            onMessage: (data) => {
                // Optimistically update the recipe data
                dispatch(
                    apiSlice.util.updateQueryData(
                        'getGeneratedRecipe',
                        recipeId,
                        () => data
                    )
                );
            },
            onError: (error) => {
                console.error('WebSocket error:', error);
                dispatch(setRecipeChatStreaming(false));
            },
            onClose: () => {
                console.log('WebSocket is closed');
                wsService.current = null;
                dispatch(setRecipeChatStreaming(false));
            }
        });

        wsService.current.connect();
    }, [dispatch]);

    const handleSubmit = useCallback(async () => {
        if (!currentPrompt.trim() || isLoading) return;

        try {
            let recipeId;
            
            if (!generatedRecipeId) {
                const newRecipeId = uuidv4();
                dispatch(setGeneratedRecipeId(newRecipeId));
                onRecipeIdChange?.(newRecipeId);
                
                await createRecipe(newRecipeId).unwrap();
                recipeId = newRecipeId;
            } else {
                recipeId = generatedRecipeId;
            }
            
            // Optimistically update the messages
            dispatch(
                apiSlice.util.updateQueryData(
                    'getGeneratedRecipe',
                    recipeId,
                    (draft) => {
                        if (!draft) {
                            return {
                                messages: [{
                                    role: "user",
                                    content: currentPrompt.trim()
                                }],
                                recipes: {}
                            };
                        }
                        if (!draft.messages) {
                            draft.messages = [];
                        }
                        draft.messages.push({
                            role: "user",
                            content: currentPrompt.trim()
                        });
                        return draft;
                    }
                )
            );
            
            // Only initiate WebSocket after recipe creation is confirmed
            initiateWebSocketConnection(currentPrompt.trim(), recipeId);
            dispatch(setCurrentPrompt('')); // Clear the prompt after sending
        } catch (error) {
            console.error('Failed to handle recipe:', error);
            if (!generatedRecipeId) {
                dispatch(setGeneratedRecipeId(null));
                onRecipeIdChange?.(null);
            }
        }
    }, [currentPrompt, isLoading, createRecipe, dispatch, generatedRecipeId, initiateWebSocketConnection, onRecipeIdChange]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="py-2 px-4 max-w-5xl mx-auto">
            <div className="flex items-center justify-center space-x-2">
                <Input
                    className="flex-grow"
                    placeholder="Describe your recipe idea..."
                    value={currentPrompt}
                    disabled={recipeChatStreaming || isLoading}
                    onChange={(e) => dispatch(setCurrentPrompt(e.target.value))}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    onClick={handleSubmit}
                    disabled={recipeChatStreaming || isLoading}
                    size="icon"
                    variant="reverse"
                    className="p-2"
                >
                    {recipeChatStreaming ? (
                        <Square className="h-5 w-5 fill-current" />
                    ) : (
                        <ArrowUp className="h-5 w-5" />
                    )}
                </Button>
            </div>
        </div>
    );
};

export default RecipeGenerationChat; 