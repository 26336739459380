import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateGroceryListItem, shiftCheckboxStatus, splitGroceryListItem, mergeWithPreviousItem, selectFilteredGroceryList } from './groceryListSlice';
import { Input } from 'src/components/Input';

export function GroceryListItem({ aisle, itemId, onNavigate }) {
    const dispatch = useDispatch();
    const inputRef = React.useRef(null);
    const filteredList = useSelector(selectFilteredGroceryList);
    const isDedupingList = useSelector(state => state.groceryList.isDedupingList);
    
    const item = filteredList[aisle]?.find(item => item.item_id === itemId);

    // Move useEffect before the early return
    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, 0);
        }
    }, [item?.item_id]); // Changed to optional chaining since item might be null

    if (!item) return null;

    const handleInputChange = (event) => {
        if (event.target.value === '' && event.key === 'Backspace') {
            dispatch(updateGroceryListItem({
                aisle,
                itemId,
                updates: { remove: true }
            }));
        } else {
            dispatch(updateGroceryListItem({
                aisle,
                itemId,
                updates: { ingredient_string: event.target.value }
            }));
        }
    };

    const handleCheckboxChange = () => {
        dispatch(shiftCheckboxStatus({ aisle, itemId }));
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const cursorPosition = event.target.selectionStart;
            dispatch(splitGroceryListItem({
                aisle,
                itemId,
                cursorPosition
            }));
            
            // Focus will move to the second item and cursor will be at start
            // We'll handle this in useEffect by setting selectionRange to 0
            return;
        }
        
        if (event.key === 'Backspace') {
            const cursorPosition = event.target.selectionStart;
            const isEmpty = event.target.value === '';
            const isAtStart = cursorPosition === 0;

            if (isAtStart) {
                event.preventDefault();
                const items = filteredList[aisle];
                const itemIndex = items.findIndex(item => item.item_id === itemId);
                const previousItemLength = itemIndex > 0 ? items[itemIndex - 1].ingredient_string.length : 0;

                if (!isEmpty) {
                    // If there's content, merge with previous line
                    dispatch(mergeWithPreviousItem({
                        aisle,
                        itemId
                    }));
                    // Navigate up and place cursor at the merge point
                    onNavigate('up', previousItemLength);
                } else {
                    // If empty, just delete and move up
                    dispatch(updateGroceryListItem({
                        aisle,
                        itemId,
                        updates: { remove: true }
                    }));
                    onNavigate('up', Number.MAX_SAFE_INTEGER);
                }
            }
        } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
            event.preventDefault();
            const cursorPosition = event.target.selectionStart;
            onNavigate(event.key === 'ArrowUp' ? 'up' : 'down', cursorPosition);
        }
    };

    return (
        <div className="pb-1" key={itemId}>
            <div className={`flex items-center h-8 rounded ${
                item.checkbox_status === 'checked' ? 'bg-gray-600' : 
                item.checkbox_status === 'confirm' ? 'border text-orange border-orange' : ''
            }`}>
                <button 
                    onClick={handleCheckboxChange} 
                    className="w-6 h-6 flex justify-center items-center rounded mr-2"
                    disabled={isDedupingList}
                >
                    {item.checkbox_status === 'unchecked' && <i className="far fa-square"></i>}
                    {item.checkbox_status === 'checked' && <i className="fas fa-check-square"></i>}
                    {item.checkbox_status === 'confirm' && <i className="fas fa-question"></i>}
                </button>
                <Input 
                    ref={inputRef}
                    variant="clean"
                    value={item.ingredient_string} 
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    disabled={isDedupingList}
                    className={isDedupingList ? 'opacity-50 cursor-not-allowed' : ''}
                />
            </div>
        </div>
    );
}
