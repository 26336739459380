import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUserQuery, useCreateUserMutation } from '../../store/apiSlice';

const ProtectedRoute = ({ children }) => {
  const session = useSelector((state) => state.auth.session);
  const location = useLocation();
  
  const { data: user, error } = useGetUserQuery(undefined, {
    skip: !session
  });
  
  const [createUser] = useCreateUserMutation();

  React.useEffect(() => {
    if (error?.status === 404 && session) {
      createUser().catch(console.error);
    }
  }, [error, createUser, session]);

  if (!session) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  // Show loading state while we're fetching/creating user
  if (!user && !error) {
    return <div>Loading...</div>; // Consider replacing with a proper loading component
  }

  return children;
};

export default ProtectedRoute;