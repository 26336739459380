import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Carousel } from 'src/components/Carousel';
import ToggleableMealIdea from './ToggleableMealIdea';
import { setSelectedMealIdeaId, setMealPlanStreaming } from './mealPlanSlice';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';
import { apiSlice, useGetMealPlanQuery } from '../../store/apiSlice';

const MealIdeaCarousel = ({ mealSpecificIdeas, mealId }) => {
  const dispatch = useDispatch();
  const selectedMealIdeaId = useSelector(state => state.mealPlan.selectedMealIdeaId);
  const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
  const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);
  const wsService = useRef(null);
  
  const { data: mealPlan } = useGetMealPlanQuery(meal_plan_id);
  
  const currentMealIdea = selectedMealIdeaId 
    ? mealPlan?.meal_ideas?.find(idea => 
        idea.id === selectedMealIdeaId && 
        idea.meal_id === mealId
    ) : null;

  useEffect(() => {
      
      if (!meal_plan_id || !selectedMealIdeaId || !currentMealIdea || currentMealIdea.hydrated === true) return;
      console.log('MealIdeaCarousel initiating websocket connection:', {
        meal_plan_id,
        selectedMealIdeaId,
        currentMealIdea,
        mealPlan,
        mealSpecificIdeas,
        mealId
      });
      
    wsService.current = new WebSocketGenerationService({
      endpoint: `hydrate_meal_idea`,
      payload: {
        meal_plan_id,
        meal_idea_id: selectedMealIdeaId,
      },
      onOpen: () => dispatch(setMealPlanStreaming(true)),
      onMessage: (data) => {
        dispatch(
          apiSlice.util.updateQueryData(
            'getMealPlan',
            meal_plan_id,
            (draft) => {
              const ideaIndex = draft.meal_ideas.findIndex(
                idea => idea.id === selectedMealIdeaId
              );
              if (ideaIndex !== -1) {
                draft.meal_ideas[ideaIndex] = data;
              }
            }
          )
        );
      },
      onError: () => dispatch(setMealPlanStreaming(false)),
      onClose: () => dispatch(setMealPlanStreaming(false))
    });

    wsService.current.connect();

    return () => {
      if (wsService.current) {
        wsService.current.disconnect();
        wsService.current = null;
      }
    };
  }, [meal_plan_id, selectedMealIdeaId, currentMealIdea?.hydrated, dispatch]);

  const handleMealIdeaToggle = (mealIdea, isActive) => {
    dispatch(setSelectedMealIdeaId(isActive ? mealIdea.id : null));
  };

  if (mealSpecificIdeas.length === 0) {
    return null;
  }

  return (
    <Carousel>
      {mealSpecificIdeas.map((mealIdea) => (
        <ToggleableMealIdea
          key={mealIdea.id}
          mealIdea={mealIdea}
          isActive={selectedMealIdeaId === mealIdea.id}
          onToggle={(isActive) => handleMealIdeaToggle(mealIdea, isActive)}
          disabled={mealPlanStreaming}
        />
      ))}
    </Carousel>
  );
};

export default MealIdeaCarousel; 