import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BuildSchedule from './BuildSchedule';
import { useGetHouseholdQuery, useCreateMealPlanMutation } from '../../store/apiSlice';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Button } from 'src/components/Button';
import FooterContext from '../../FooterContext';

function BuildMealPlanStructure() {
  const [schedule, setSchedule] = useState({});
  const navigate = useNavigate();
  const { setShowFooter } = useContext(FooterContext);

  const { data: household, isLoading, isError } = useGetHouseholdQuery();
  const [createMealPlan] = useCreateMealPlanMutation();

  useEffect(() => {
    if (household) {
      const initialSchedule = createInitialSchedule(household);
      setSchedule(initialSchedule);
    }
  }, [household]);

  useEffect(() => {
    setShowFooter(false);

    return () => {
      setShowFooter(true);
    };
  }, [setShowFooter]);

  const createInitialSchedule = (household) => {
    const mealTypes = ['breakfast', 'lunch', 'snack', 'dinner'];
    let schedule = {};
  
    for (let i = 0; i < 14; i++) {
      const date = moment().add(i, 'days');
      const formattedDate = date.format('YYYY-MM-DD');
  
      mealTypes.forEach(mealType => {
        const mealId = uuidv4();
        schedule[mealId] = {
          id: mealId,
          date: date.toDate(),
          formattedDate: formattedDate,
          meal_type: mealType,
          users: household.reduce((acc, member) => {
            acc[member.user_id] = false;
            return acc;
          }, {}),
          leftoverFrom: 'fresh'
        };
      });
    }
    return schedule;
  };

  const updateSchedule = (mealId, field, value) => {
    setSchedule(prevSchedule => ({
      ...prevSchedule,
      [mealId]: {
        ...prevSchedule[mealId],
        [field]: value
      }
    }));
  };

  const submitWizard = async () => {
    try {
        // Filter meals where at least one user is selected
        const mealsToCreate = Object.values(schedule).filter(meal => 
            Object.values(meal.users).some(user => user)
        );

        // Transform users object to array of user IDs
        const getMealRecipients = (users) => {
            return Object.entries(users)
                .filter(([_, isSelected]) => isSelected)
                .map(([userId, _]) => userId);
        };

        // Transform meals into the new format
        const transformedMeals = mealsToCreate.map(meal => ({
            date: meal.formattedDate,
            meal_type: meal.meal_type,
            published: false,
            meal_recipients: getMealRecipients(meal.users),
            ...(meal.leftoverFrom !== 'fresh' && {
                primary_meal: {
                    date: schedule[meal.leftoverFrom].formattedDate,
                    meal_type: schedule[meal.leftoverFrom].meal_type
                }
            })
        }));

        // Create meal plan with all meals in a single request
        const response = await createMealPlan({ 
            meal_inspiration: 'No inspiration provided',
            meals: transformedMeals
        }).unwrap();
        console.log("Build meal plan response", response);

        navigate(`/meal_plan/${response.meal_plan_id}`);
    } catch (error) {
        console.error('Error creating meal plan:', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading household data</div>;

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow overflow-y-auto">
        <div className="flex flex-col items-center w-full">
          <div className="w-full max-w-7xl px-2">
            <div className="pb-20">
              <div className="text-xl font-bold py-4 pl-2">What meals do you want to plan?</div>
              <BuildSchedule 
                schedule={schedule} 
                household={household} 
                updateSchedule={updateSchedule} 
              />
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 p-4 flex justify-center">
        <Button onClick={submitWizard}>Create Meal Plan</Button>
      </div>
    </div>
  );
}

export default BuildMealPlanStructure;
