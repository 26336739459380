// src/components/MealList.js
import React, { useState } from 'react';
import { useListMealsQuery, useListRecipeLibraryQuery } from 'src/store/apiSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ScheduledMeal from './ScheduledMeal';
import { formatDate } from '../../utils';
import ForTheWeek from './ForTheWeek';
import { Button } from 'src/components/Button';
import ScheduleGrid from './ScheduleGrid';

export default function MealList() {
    const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));  
    const navigate = useNavigate();

    const startDate = currentWeekStart.format('YYYY-MM-DD')
    const endDate = currentWeekStart.clone().endOf('week').format('YYYY-MM-DD')
    
    const {
        data: { meals, meal_plans, grocery_lists } = {},
        error: mealsError,
        isFetching: mealsFetching,
    } = useListMealsQuery({
        startDate,
        endDate,
        includeMealPlans: true,
        includeGroceryLists: true,
    });

    const {
        data: recipeLibrary,
        error: recipesError,
        isFetching: recipesFetching
    } = useListRecipeLibraryQuery();

    const goToNextWeek = () => {
        setCurrentWeekStart(prev => prev.clone().add(1, 'week'));
    };

    const goToPreviousWeek = () => {
        setCurrentWeekStart(prev => prev.clone().subtract(1, 'week'));
    };

    const groupMealsByDate = (meals) => {
        return meals.reduce((acc, meal) => {
            // Include meals with non-empty recipes array or non-null primary_meal
            if ((meal.recipes && meal.recipes.length > 0) || meal.primary_meal !== null) {
                const date = moment(meal.date).format('YYYY-MM-DD');
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date].push(meal);
            }
            return acc;
        }, {});
    };
    
    const groupedMeals = meals ? groupMealsByDate(meals) : {};   
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => 
        currentWeekStart.clone().add(i, 'days').format('YYYY-MM-DD')
    );

    const isFetching = mealsFetching || recipesFetching;
    const hasError = mealsError || recipesError;

    const getWeekDisplayFormat = (startDate) => {
        const endDate = startDate.clone().endOf('week');
        
        // Same month
        if (startDate.month() === endDate.month()) {
            return startDate.format('MMMM YYYY');
        }
        
        // Different months
        return `${startDate.format('MMMM YYYY')} - ${endDate.format('MMMM YYYY')}`;
    };

    return (
        <div className="justify-center mx-auto px-4">
            <div className="flex flex-wrap justify-between pt-4 pb-2 items-end">
                <div className="text-2xl font-bold">
                    Schedule
                </div>
            </div>
            <div className="flex justify-between items-center my-4">
                <Button variant="neutral" size="sm" onClick={goToPreviousWeek}>{`< Previous Week`}</Button>
                <div className="text-center">
                    <p className="text-sm">{getWeekDisplayFormat(currentWeekStart)}</p>
                </div>
                <Button variant="neutral" size="sm" onClick={goToNextWeek}>{`Next Week >`}</Button>
            </div>
            
            {isFetching ? (
                <div className="text-center py-4">
                    <p>Loading...</p>
                </div>
            ) : hasError ? (
                <div className="text-center py-4 text-red-500">
                    <p>Error loading data.</p>
                </div>
            ) : (
                <>
                    <ForTheWeek mealPlans={meal_plans || []} groceryLists={grocery_lists || []} />
                    <ScheduleGrid>
                        {daysOfWeek.map(day => (
                            <div key={day} className="w-full max-w-[384px] flex flex-col items-start">
                                <div className="text-xl mb-4 flex flex-col items-center">
                                    <div className="text-sm font-medium text-gray-500">
                                        {moment(day).format('ddd').toUpperCase()}
                                    </div>
                                    <div className="text-xl font-bold">
                                        {moment(day).format('D')}
                                    </div>
                                </div>
                                {groupedMeals[day]?.sort((a, b) => {
                                    const mealOrder = ['breakfast', 'lunch', 'snack', 'dinner'];
                                    return mealOrder.indexOf(a.meal_type) - mealOrder.indexOf(b.meal_type);
                                }).map(meal => (
                                    <div key={meal.id} className="mb-2">
                                        <ScheduledMeal meal={meal} mealType={meal.meal_type}/>
                                    </div>
                                ))}
                                {(!groupedMeals[day] || groupedMeals[day].length === 0) && (
                                    <p className="text-gray-800 italic text-sm">No meals planned... yet</p>
                                )}
                            </div>
                        ))}
                    </ScheduleGrid>
                </>
            )}
        </div>
    );
}