import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Loader2, Check } from 'lucide-react';
import { saveStatus } from './groceryListSlice';

export function SaveStatus() {
    const status = useSelector(state => state.groceryList.saveStatus);

    if (status === saveStatus.IDLE) return null;

    return (
        <div className="flex items-center ml-4">
            {status === saveStatus.PENDING && (
                <Loader2 className="w-4 h-4 animate-spin text-gray-500" />
            )}
            {status === saveStatus.SUCCESS && (
                <Check className="w-4 h-4 text-green-500" />
            )}
        </div>
    );
} 