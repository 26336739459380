import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/Card';
import { formatDate, formatDateToWeekday } from '../../utils';

const ForTheWeek = ({ mealPlans, groceryLists }) => {
  const navigate = useNavigate();

  const handleCardClick = (type, id) => {
    navigate(`/${type}/${id}`);
  };

  // Check if there are any meal plans or grocery lists to display
  if (mealPlans.length === 0 && groceryLists.length === 0) {
    return null; // Return null if both arrays are empty
  }

  return (
    <div className="mb-4">
      <div className="flex flex-wrap gap-4 w-full">
        {mealPlans.map((plan, index) => (
          <Card 
            key={`meal-plan-${index}`} 
            className="w-fit max-w-full cursor-pointer transition-colors p-2"
            boxShadowColor="purple"
            onClick={() => handleCardClick('meal_plan', plan.id)}
          >
            <span className="text-sm inline-block">
              <span className="font-bold text-lg">Meal Plan</span> for {plan.meals.map(meal => 
                `${formatDateToWeekday(meal.date)} (${meal.meal_type})`
              ).join(', ')}
            </span>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ForTheWeek;