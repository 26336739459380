import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/apiSlice';
import { SheetHeader } from '../../components/Sheet';
import PreferenceStickyNote from '../profile/PreferenceStickyNote';
import { Button } from '../../components/Button';
import { v4 as uuidv4 } from 'uuid';
import {
  setMealPlanningPreferenceEditing,
  clearMealPlanningPreferenceEditing,
  setMealPlanningPreferenceAdding,
  clearMealPlanningPreferenceAdding,
} from './mealPlanSlice';
import EditPreferenceDialog from '../profile/EditPreferenceDialog';

const MealPlanningPreferencesSheetContent = () => {
  const dispatch = useDispatch();
  const { data: user } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  
  const { editingPreference, addingPreference } = useSelector(
    (state) => state.mealPlan.sheets.mealPlanningPreferences
  );

  const handleEditPreference = (id, preference) => {
    dispatch(setMealPlanningPreferenceEditing({ id, preference }));
  };

  const handleUpdatePreference = (preferenceKey, newValue) => {
    if (user?.preferences?.meal_planning_ideas) {
      const updatedPreferences = {
        ...user.preferences,
        meal_planning_ideas: user.preferences.meal_planning_ideas.map(item =>
          item.id === editingPreference.id ? { ...item, preference: newValue } : item
        )
      };
      updateUser({ preferences: updatedPreferences });
    }
    dispatch(clearMealPlanningPreferenceEditing());
  };

  const handleDeletePreference = (id) => {
    if (user?.preferences?.meal_planning_ideas) {
      const updatedPreferences = {
        ...user.preferences,
        meal_planning_ideas: user.preferences.meal_planning_ideas.filter(item => item.id !== id)
      };
      updateUser({ preferences: updatedPreferences });
    }
  };

  const handleAddPreference = () => {
    dispatch(setMealPlanningPreferenceAdding({ id: uuidv4(), preference: '' }));
  };

  const handleSaveNewPreference = (preferenceKey, newValue) => {
    if (user?.preferences) {
      const updatedPreferences = {
        ...user.preferences,
        meal_planning_ideas: [
          ...(user.preferences.meal_planning_ideas || []),
          { id: uuidv4(), preference: newValue }
        ]
      };
      updateUser({ preferences: updatedPreferences });
    }
    dispatch(clearMealPlanningPreferenceAdding());
  };

  const handleCloseDialog = () => {
    dispatch(clearMealPlanningPreferenceEditing());
    dispatch(clearMealPlanningPreferenceAdding());
  };

  return (
    <>
      <SheetHeader>
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <div className="text-lg font-semibold">Meal Planning Notes</div>
            <div className="text-sm text-gray-600">Mise En stores your preferences to your profile so it remembers what you like.</div>
          </div>
          <Button onClick={handleAddPreference}>Add Note</Button>
        </div>
      </SheetHeader>
      <div className="p-2">
        {user?.preferences?.meal_planning_ideas?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {user.preferences.meal_planning_ideas.map(({ id, preference }) => (
              <PreferenceStickyNote
                key={id}
                preferenceKey={id}
                value={preference}
                onEdit={handleEditPreference}
                onDelete={handleDeletePreference}
              />
            ))}
          </div>
        ) : (
          <div> Currently you don't have any preferences for meal planning. Add a note about how you like to meal plan (e.g. "For weeknight meals, I prefer a simple protein and vegetable") and I'll remember it going forward.</div>
        )}
      </div>

      {(editingPreference || addingPreference) && (
        <EditPreferenceDialog
          isOpen={true}
          preferenceKey={editingPreference?.id || addingPreference?.id}
          value={editingPreference?.preference || ''}
          onSave={editingPreference ? handleUpdatePreference : handleSaveNewPreference}
          onClose={handleCloseDialog}
          isAdding={!!addingPreference}
        />
      )}
    </>
  );
};

export default MealPlanningPreferencesSheetContent;
