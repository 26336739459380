import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from './Button';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from './DropdownMenu';
import ActionLibrary from './ActionLibrary';
import AddExternalRecipe from '../apps/recipeLibrary/AddExternalRecipe';
import { openAddExternalRecipeModal } from '../store/globalSlice';

function Navbar() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const navItems = [
    { label: 'Schedule', path: '/schedule' },
    { label: 'Library', path: '/recipes' },
  ];

  return (
    <nav className="bg-gray-100 border-b border-black py-2">
      <div className="flex justify-between items-center px-4">
        <div className="flex items-center space-x-4">
          <NavLink to="/recipes" className="text-xl font-bold">
            Mise En
          </NavLink>
          <div className="hidden md:flex space-x-2">
            {navItems.map((item) => (
              <NavLink key={item.path} to={item.path}>
                <Button variant="link">{item.label}</Button>
              </NavLink>
            ))}
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <div className="hidden md:block">
            <ActionLibrary />
          </div>

          <div className="hidden md:block">
            <NavLink to="/profile">
              <Button variant="neutral">Profile</Button>
            </NavLink>
          </div>

          <div className="md:hidden">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>Plan</DropdownMenuLabel>
                <DropdownMenuItem asChild>
                  <NavLink to="/schedule">View Schedule</NavLink>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link to="/build_meal_plan">Create Meal Plan</Link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuLabel>Recipes</DropdownMenuLabel>
                <DropdownMenuItem asChild>
                  <NavLink to="/recipes">View Library</NavLink>
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => dispatch(openAddExternalRecipeModal())}>
                  Add External Recipe
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <Link to="/create_recipe">Create AI Recipe</Link>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuLabel>Settings</DropdownMenuLabel>
                <DropdownMenuItem asChild>
                  <NavLink to="/profile">View Profile</NavLink>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <AddExternalRecipe />
    </nav>
  );
}

export default Navbar;