import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NavbarContext from '../../NavBarContext';
import { Button } from '../../components/Button';
import { setRedirectPath, signInWithGoogle } from '../../store/authSlice';

const SignIn = () => {
  const { setShowNavbar } = useContext(NavbarContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/schedule';

  useEffect(() => {
    dispatch(setRedirectPath(from));
    setShowNavbar(false);
    return () => setShowNavbar(true);
  }, [setShowNavbar, from, dispatch]);

  const handleGoogleSignIn = async () => {
    try {
      await dispatch(signInWithGoogle()).unwrap();
    } catch (error) {
      console.error('Failed to sign in:', error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="flex flex-col items-center space-y-5 w-full max-w-md px-4">
        <Button variant="neutral" onClick={handleGoogleSignIn}>Sign In with Google</Button>
      </div>
    </div>
  );
};

export default SignIn;