import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  meal_plan_id: null,
  mealPlanStreaming: false,
  showNutrition: false,
  showParticipants: false,
  showLeftovers: true,
  selectedMealIdeaId: null,
  adjustQuantityModal: {
    isOpen: false,
    recipeId: null,
    mealId: null,
    initialMultiplier: 1,
  },
  addToMealModal: {
    isOpen: false,
    recipeId: null,
    recipeName: null,
  },
  mealNotes: {},
  sheets: {
    recipeLibrary: {
      isOpen: false,
      selectedMealId: null,
    },
    createGeneratedRecipe: {
      isOpen: false,
      selectedMealId: null,
      generatedRecipeId: null,
      allowSelectionOfMeal: false,
    },
    externalSearchRecipe: {
      isOpen: false,
      selectedMealId: null,
      previousRecipeId: null,
      searchTerm: '',
    },
    mealPlanningPreferences: {
      isOpen: false,
      editingPreference: null,
      addingPreference: null
    },
  },
  activeView: 'mealPlan',
};

const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState,
  reducers: {
    setMealPlanId: (state, action) => {
      state.meal_plan_id = action.payload;
    },
    setMealPlanStreaming: (state, action) => {
      state.mealPlanStreaming = action.payload;
    },
    openAdjustQuantityModal: (state, action) => {
      state.adjustQuantityModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        mealId: action.payload.mealId,
        initialMultiplier: action.payload.initialMultiplier,
      };
    },
    closeAdjustQuantityModal: (state) => {
      state.adjustQuantityModal = {
        isOpen: false,
        recipeId: null,
        mealId: null,
        initialMultiplier: 1,
      };
    },
    openAddToMealModal: (state, action) => {
      state.addToMealModal = {
        isOpen: true,
        recipeId: action.payload.recipeId,
        recipeName: action.payload.recipeName,
      };
    },
    closeAddToMealModal: (state) => {
      state.addToMealModal = {
        isOpen: false,
        recipeId: null,
        recipeName: null,
      };
    },
    adjustRecipeQuantity: (state, action) => {
      const { recipeId, multiplier } = action.payload;
      state.meals.forEach(meal => {
        const recipe = meal.recipes.find(r => r.recipe.recipe_id === recipeId);
        if (recipe) {
          recipe.multiplier = multiplier;
        }
      });
    },
    setRecipeIdeasStreaming: (state, action) => {
      state.recipeIdeasStreaming = action.payload;
    },
    toggleShowNutrition: (state) => {
      state.showNutrition = !state.showNutrition;
    },
    toggleShowParticipants: (state) => {
      state.showParticipants = !state.showParticipants;
    },
    toggleShowLeftovers: (state) => {
      state.showLeftovers = !state.showLeftovers;
    },
    setSelectedMealIdeaId: (state, action) => {
      state.selectedMealIdeaId = action.payload;
    },
    clearSelectedMealIdea: (state) => {
      state.selectedMealIdeaId = null;
    },
    initializeMealNotes: (state, action) => {
      state.mealNotes = action.payload;
    },
    updateMealNote: (state, action) => {
      const { mealId, note } = action.payload;
      state.mealNotes[mealId] = note;
    },
    setRecipeLibrarySelectedMealId: (state, action) => {
      state.sheets.recipeLibrary.selectedMealId = action.payload;
    },
    openRecipeLibrarySheet: (state) => {
      state.sheets.recipeLibrary.isOpen = true;
    },
    closeRecipeLibrarySheet: (state) => {
      state.sheets.recipeLibrary.isOpen = false;
    },
    openCreateGeneratedRecipeSheet: (state, action) => {
      state.sheets.createGeneratedRecipe.isOpen = true;

      if (action.payload?.selectedMealId !== undefined) {
        state.sheets.createGeneratedRecipe.selectedMealId = action.payload.selectedMealId;
      }
      if (action.payload?.generatedRecipeId !== undefined) {
        state.sheets.createGeneratedRecipe.generatedRecipeId = action.payload.generatedRecipeId;
      }
      if (action.payload?.allowSelectionOfMeal !== undefined) {
        state.sheets.createGeneratedRecipe.allowSelectionOfMeal = action.payload.allowSelectionOfMeal;
      }
      if (action.payload?.currentPrompt !== undefined) {
        state.sheets.createGeneratedRecipe.currentPrompt = action.payload.currentPrompt;
      }
    },
    closeCreateGeneratedRecipeSheet: (state) => {
      state.sheets.createGeneratedRecipe.isOpen = false;
    },
    openExternalSearchRecipeSheet: (state) => {
      state.sheets.externalSearchRecipe.isOpen = true;
    },
    closeExternalSearchRecipeSheet: (state) => {
      state.sheets.externalSearchRecipe.isOpen = false;
    },
    setCreateGeneratedRecipeSelectedMealId: (state, action) => {
      state.sheets.createGeneratedRecipe.selectedMealId = action.payload;
    },
    setCreateGeneratedRecipeId: (state, action) => {
      state.sheets.createGeneratedRecipe.generatedRecipeId = action.payload;
    },
    setExternalSearchRecipeSelectedMealId: (state, action) => {
      state.sheets.externalSearchRecipe.selectedMealId = action.payload;
    },
    setExternalSearchRecipePreviousRecipeId: (state, action) => {
      state.sheets.externalSearchRecipe.previousRecipeId = action.payload;
    },
    setExternalSearchRecipeSearchTerm: (state, action) => {
      state.sheets.externalSearchRecipe.searchTerm = action.payload;
    },
    openMealPlanningPreferencesSheet: (state) => {
      state.sheets.mealPlanningPreferences.isOpen = true;
    },
    closeMealPlanningPreferencesSheet: (state) => {
      state.sheets.mealPlanningPreferences.isOpen = false;
    },
    setMealPlanningPreferenceEditing: (state, action) => {
      state.sheets.mealPlanningPreferences.editingPreference = action.payload;
    },
    clearMealPlanningPreferenceEditing: (state) => {
      state.sheets.mealPlanningPreferences.editingPreference = null;
    },
    setMealPlanningPreferenceAdding: (state, action) => {
      state.sheets.mealPlanningPreferences.addingPreference = action.payload;
    },
    clearMealPlanningPreferenceAdding: (state) => {
      state.sheets.mealPlanningPreferences.addingPreference = null;
    },
    resetCreateGeneratedRecipeSheet: (state) => {
      state.sheets.createGeneratedRecipe = {
        isOpen: false,
        selectedMealId: null,
        generatedRecipeId: null,
      };
    },
    setAllowSelectionOfMeal: (state, action) => {
      state.sheets.createGeneratedRecipe.allowSelectionOfMeal = action.payload;
    },
    setActiveView: (state, action) => {
      state.activeView = action.payload;
    },
  }
});

export const {
  setMealPlanId,
  setMealPlanStreaming,
  openAdjustQuantityModal,
  closeAdjustQuantityModal,
  openAddToMealModal,
  closeAddToMealModal,
  adjustRecipeQuantity,
  setRecipeIdeasStreaming,
  toggleShowNutrition,
  toggleShowParticipants,
  toggleShowLeftovers,
  setSelectedMealIdeaId,
  clearSelectedMealIdea,
  initializeMealNotes,
  updateMealNote,
  setRecipeLibrarySelectedMealId,
  openRecipeLibrarySheet,
  closeRecipeLibrarySheet,
  openCreateGeneratedRecipeSheet,
  closeCreateGeneratedRecipeSheet,
  openExternalSearchRecipeSheet,
  closeExternalSearchRecipeSheet,
  setCreateGeneratedRecipeSelectedMealId,
  setCreateGeneratedRecipeId,
  setExternalSearchRecipeSelectedMealId,
  setExternalSearchRecipePreviousRecipeId,
  setExternalSearchRecipeSearchTerm,
  openMealPlanningPreferencesSheet,
  closeMealPlanningPreferencesSheet,
  setMealPlanningPreferenceEditing,
  clearMealPlanningPreferenceEditing,
  setMealPlanningPreferenceAdding,
  clearMealPlanningPreferenceAdding,
  resetCreateGeneratedRecipeSheet,
  setAllowSelectionOfMeal,
  setActiveView,
} = mealPlanSlice.actions;

export default mealPlanSlice.reducer;
