import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RecipeGeneration from './RecipeGeneration';
import NavbarContext from '../../NavBarContext';
import FooterContext from '../../FooterContext';

const RecipeGenerationPage = () => {
    const { generated_recipe_id } = useParams();
    const navigate = useNavigate();
    const { setShowNavbar } = useContext(NavbarContext);
    const { setShowFooter } = useContext(FooterContext);

    useEffect(() => {
        setShowNavbar(true);
        setShowFooter(false);

        return () => {
            setShowFooter(true);
        };
    }, [setShowNavbar, setShowFooter]);

    const handleRecipeIdChange = (newRecipeId) => {
        if (newRecipeId) {
            navigate(`/create_recipe/${newRecipeId}`, { replace: true });
        } else {
            navigate(-1);
        }
    };

    return (
        <RecipeGeneration 
            generatedRecipeId={generated_recipe_id}
            onRecipeIdChange={handleRecipeIdChange}
            isFullPage={true}
        />
    );
};

export default RecipeGenerationPage; 