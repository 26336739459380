import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase-client';
import { Button } from '../../components/Button';
import { useGetUserQuery, useUpdateUserMutation } from '../../store/apiSlice';
import { v4 as uuidv4 } from 'uuid';
import ManageHousehold from './ManageHousehold';
import ManagePreferences from './ManagePreferences';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/authSlice';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user, isLoading, isError } = useGetUserQuery();
  const [editingPreference, setEditingPreference] = useState(null);
  const [addingPreference, setAddingPreference] = useState(null);
  const [updateUser] = useUpdateUserMutation();

  const handleSignOut = async () => {
    try {
      await dispatch(signOut()).unwrap();
      navigate('/signin');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleEditPreference = (category, id, preference) => {
    setEditingPreference({ category, id, preference });
    setAddingPreference(null);
  };

  const handleUpdatePreference = (category, id, newPreference) => {
    if (user && user.preferences && user.preferences[category]) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: user.preferences[category].map(item => 
          item.id === id ? { ...item, preference: newPreference } : item
        )
      };
      updateUser({ preferences: updatedPreferences });
    }
  };

  const handleDeletePreference = (category, id) => {
    if (user && user.preferences && user.preferences[category]) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: user.preferences[category].filter(item => item.id !== id)
      };
      updateUser({ preferences: updatedPreferences });
    }
  };

  const handleAddPreference = (category) => {
    setAddingPreference({ category, id: uuidv4(), preference: '' });
    setEditingPreference(null);
  };

  const handleSaveNewPreference = (category, newPreference) => {
    if (user && user.preferences) {
      const updatedPreferences = {
        ...user.preferences,
        [category]: [...(user.preferences[category] || []), { id: uuidv4(), preference: newPreference }]
      };
      updateUser({ preferences: updatedPreferences });
    }
    setAddingPreference(null);
  };

  const handleClosePreferenceDialog = () => {
    setEditingPreference(null);
    setAddingPreference(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || !user) {
    return <div>Error loading user data</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex justify-between items-center mb-8">
        <h2>Profile</h2>
        <Button onClick={handleSignOut}>Sign Out</Button>
      </div>
      
      <ManageHousehold
        user={user}
      />

      <ManagePreferences
        user={user}
        editingPreference={editingPreference}
        addingPreference={addingPreference}
        onEditPreference={handleEditPreference}
        onUpdatePreference={handleUpdatePreference}
        onDeletePreference={handleDeletePreference}
        onAddPreference={handleAddPreference}
        onSaveNewPreference={handleSaveNewPreference}
        onClosePreferenceDialog={handleClosePreferenceDialog}
      />
    </div>
  );
};

export default Profile;
