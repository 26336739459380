import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './store/apiSlice';
import mealPlanReducer from './apps/mealPlan/mealPlanSlice';
import recipeGenerationReducer from './apps/recipeGeneration/recipeGenerationSlice';
import globalReducer from './store/globalSlice';
import profileReducer from './apps/profile/profileSlice';
import authReducer from './store/authSlice';
import groceryListReducer from './apps/groceryList/groceryListSlice';
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    mealPlan: mealPlanReducer,
    global: globalReducer,
    profile: profileReducer,
    recipeGeneration: recipeGenerationReducer,
    auth: authReducer,
    groceryList: groceryListReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

// Add these type definitions
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;