import React from 'react';
import { formatDate } from '../../utils';
import { Toggle } from 'src/components/Toggle';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/Select';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { SimpleMultiSelect } from 'src/components/SimpleMultiSelect';

function BuildSchedule({ schedule, updateSchedule, household }) {
  const handleCheckboxChange = (mealId, userId, isChecked) => {
    updateSchedule(mealId, 'users', {
      ...schedule[mealId].users,
      [userId]: isChecked
    });
  };

  const toggleEveryone = (mealId, isChecked) => {
    const updatedUsers = {};
    household.forEach(member => {
      updatedUsers[member.user_id] = isChecked;
    });
    updateSchedule(mealId, 'users', updatedUsers);
  };

  const handleSelectorChange = (mealId, value) => {
    updateSchedule(mealId, 'leftoverFrom', value);
  };

  const getLeftoverOptions = (currentMeal) => {
    const options = [{ value: 'fresh', label: 'Fresh Meal' }];
    
    Object.values(schedule).forEach(meal => {
      if (new Date(meal.date) < new Date(currentMeal.date) || 
          (new Date(meal.date).getTime() === new Date(currentMeal.date).getTime() && 
           mealOrder.indexOf(meal.meal_type) < mealOrder.indexOf(currentMeal.meal_type))) {
        if (Object.values(meal.users).some(Boolean)) {
          options.push({
            value: meal.id,
            label: `Leftovers from ${formatDate(meal.date)}'s ${meal.meal_type}`
          });
        }
      }
    });

    return options;
  };

  const mealOrder = ['breakfast', 'lunch', 'snack', 'dinner'];

  const sortedSchedule = Object.values(schedule).sort((a, b) => {
    return new Date(a.date) - new Date(b.date) || 
           mealOrder.indexOf(a.meal_type) - mealOrder.indexOf(b.meal_type);
  });

  const groupedSchedule = sortedSchedule.reduce((acc, meal) => {
    const date = new Date(meal.date).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(meal);
    return acc;
  }, {});

  const handleMealToggle = (mealId, pressed) => {
    const updatedUsers = {};
    if (pressed) {
      // When enabling a meal, select all household members by default
      household.forEach(member => {
        updatedUsers[member.user_id] = true;
      });
    } else {
      // When disabling a meal, deselect all household members
      household.forEach(member => {
        updatedUsers[member.user_id] = false;
      });
    }
    updateSchedule(mealId, 'users', updatedUsers);
  };

  const handleUserSelection = (mealId, selectedUserIds) => {
    const updatedUsers = {};
    household.forEach(member => {
      updatedUsers[member.user_id] = selectedUserIds.includes(member.user_id);
    });
    updateSchedule(mealId, 'users', updatedUsers);
  };

  return (
    <div className="w-full">
      {Object.entries(groupedSchedule).map(([date, meals]) => (
        <div key={date}>
          <div className="text-xl font-bold py-4 pl-2">
            {formatDate(new Date(date))}
          </div>
          {meals.map(meal => {
            const isScheduled = Object.values(meal.users).some(Boolean);
            const selectedUserIds = household
              .filter(member => meal.users[member.user_id])
              .map(member => member.user_id);

            return (
              <div key={meal.id} className="mb-4">
                <Toggle
                  variant="neutral"
                  pressed={isScheduled}
                  onPressedChange={(pressed) => handleMealToggle(meal.id, pressed)}
                  className="text-lg justify-start py-2 flex items-center gap-2"
                >
                  {meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1)}
                </Toggle>

                {isScheduled && (
                  <div className="flex gap-4 items-center">
                    <Select
                      value={meal.leftoverFrom || 'fresh'} 
                      variant="text"
                      onValueChange={(value) => updateSchedule(meal.id, 'leftoverFrom', value)}
                    >
                      <SelectTrigger className="w-fit" variant="text" size="multiselect">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent variant="text">
                        {getLeftoverOptions(meal).map(option => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {household.length > 1 && (
                      <>
                        <span className="text-gray-600">for</span>
                        <SimpleMultiSelect
                          options={household.map(member => ({
                            value: member.user_id,
                            label: member.name || member.user_id
                          }))}
                          value={selectedUserIds}
                          onChange={(selected) => handleUserSelection(meal.id, selected)}
                          className="w-fit"
                          placeholder="Select members..."
                          allSelectedText="everyone"
                          variant="text"
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
}

export default BuildSchedule;
