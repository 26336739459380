import React, { useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetMealPlanQuery, useListMealsQuery, apiSlice } from '../../store/apiSlice';
import { setMealPlanStreaming, clearSelectedMealIdea } from './mealPlanSlice';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import WebSocketGenerationService from '../../services/WebSocketGenerationService';
import { ArrowUp, Square } from 'lucide-react';
import { useWebSocketGeneration } from './hooks/useWebSocketGeneration';

const MealPlanChat = () => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);
    const { data: mealPlan, isLoading, error, refetch } = useGetMealPlanQuery(meal_plan_id);
    const { data: mealsData } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const [feedbackValue, setFeedbackValue] = useState('');
    
    const { initiateWebSocketConnection } = useWebSocketGeneration(meal_plan_id, mealsData);

    const handleFeedbackSubmit = useCallback(() => {
        initiateWebSocketConnection(feedbackValue.trim());
        setFeedbackValue('');
    }, [feedbackValue, initiateWebSocketConnection]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleFeedbackSubmit();
        }
    };

    if (isLoading) {
        return <div>Loading meal plan...</div>;
    }

    if (error) {
        return <div>Error loading meal plan: {error.message}</div>;
    }

    return (
        <div className="w-full py-4 px-4">
            <div className="flex space-x-2">
                <Input
                    className="flex-grow"
                    placeholder="Any feedback on the ideas?"
                    value={feedbackValue}
                    disabled={mealPlanStreaming}
                    onChange={(e) => setFeedbackValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <Button
                    onClick={handleFeedbackSubmit}
                    disabled={mealPlanStreaming}
                    variant="reverse"
                    size={!feedbackValue.trim() ? "default" : "icon"}
                    className="p-2"
                >
                    {!feedbackValue.trim() ? (
                        "Just give me more ideas"
                    ) : mealPlanStreaming ? (
                        <Square className="h-5 w-5 fill-current" />
                    ) : (
                        <ArrowUp className="h-5 w-5" />
                    )}
                </Button>
            </div>
        </div>
    );
};

export default MealPlanChat;
