import React from 'react';
import { SheetHeader, SheetTitle } from '../../components/Sheet';
import MealSelectorSheetHeader from './MealSelectorSheetHeader';
import { useDispatch, useSelector } from 'react-redux';
import { setExternalSearchRecipeSelectedMealId, setExternalSearchRecipeSearchTerm } from './mealPlanSlice';
import FocusedTextInput from '../../components/FocusedTextInput';
import { useSearchRecipesWithParamsQuery, useAddRecipeToMealMutation } from '../../store/apiSlice';
import RecipeItem from '../../components/RecipeItem';

const ExternalSearchRecipeSheetContent = () => {
  const dispatch = useDispatch();
  const selectedMealId = useSelector(state => 
    state.mealPlan.sheets.externalSearchRecipe.selectedMealId
  );
  const searchTerm = useSelector(state => 
    state.mealPlan.sheets.externalSearchRecipe.searchTerm
  );

  const { data: searchResults, isLoading } = useSearchRecipesWithParamsQuery(
    { searchTerm, maxValues: 15 },
    { skip: !searchTerm }
  );

  const [addRecipeToMeal] = useAddRecipeToMealMutation();

  const getSelectedMealId = () => selectedMealId;
  const handleMealSelect = (mealId) => {
    dispatch(setExternalSearchRecipeSelectedMealId(mealId));
  };

  const handleSearchChange = (value) => {
    dispatch(setExternalSearchRecipeSearchTerm(value));
  };

  const handleAddToMeal = async (recipe) => {
    if (!selectedMealId) {
      // You might want to show a toast or alert here
      console.error('No meal selected');
      return;
    }

    try {
      await addRecipeToMeal({
        meal_id: selectedMealId,
        recipe_id: recipe.recipe_id,
        multiplier: 1, // Default multiplier
        recipe: recipe // Pass the full recipe object for optimistic updates
      });
      // You might want to show a success toast here
    } catch (error) {
      // You might want to show an error toast here
      console.error('Failed to add recipe to meal:', error);
    }
  };

  return (
    <>
      <SheetHeader>
        <MealSelectorSheetHeader 
          getSelectedMealId={getSelectedMealId}
          onValueChange={handleMealSelect}
        />
      </SheetHeader>
      <div className="p-4">
        <FocusedTextInput
          className="w-full mb-4"
          placeholder="Search for recipes..."
          value={searchTerm}
          onFocusChange={handleSearchChange}
        />
        
        {isLoading && (
          <div className="flex justify-center">
            <span className="loading loading-dots loading-lg"/>
          </div>
        )}

        {searchResults && (
          <div className="flex flex-wrap gap-2">
            {searchResults.map(recipe => (
              <RecipeItem
                key={recipe.recipe_id}
                recipe={recipe}
                actions={selectedMealId ? [
                  {
                    actionText: 'Add to Meal',
                    actionHandler: () => handleAddToMeal(recipe) // Pass the full recipe object
                  }
                ] : []}
              />
            ))}
          </div>
        )}

        {searchResults && searchResults.length === 0 && searchTerm && (
          <div className="text-center text-gray-500">
            No recipes found for "{searchTerm}"
          </div>
        )}
      </div>
    </>
  );
};

export default ExternalSearchRecipeSheetContent;