import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "src/lib/utils"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { Button } from "./Button"
import 'swiper/css'

const carouselVariants = cva(
  'relative group w-full',
  {
    variants: {
      size: {
        default: '',
        sm: '',
        lg: '',
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
)

interface CarouselProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof carouselVariants> {
  children: React.ReactNode
  autoScroll?: boolean
}

const Carousel = React.forwardRef<HTMLDivElement, CarouselProps>(
  ({ className, size, children, autoScroll = false, ...props }, ref) => {
    const [swiperInstance, setSwiperInstance] = React.useState<SwiperType | null>(null);
    const prevSlideCount = React.useRef(0);

    const slideCount = React.Children.count(children);

    React.useEffect(() => {
      if (!swiperInstance) return;

      swiperInstance.update();
      
      if (autoScroll) {
        const transitionOptions = { speed: 300 };
        
        if (slideCount > prevSlideCount.current) {
          swiperInstance.slideTo(prevSlideCount.current, transitionOptions.speed);
        } else if (slideCount < prevSlideCount.current) {
          swiperInstance.slideTo(0, transitionOptions.speed);
        }
      }

      prevSlideCount.current = slideCount;
    }, [slideCount, swiperInstance, autoScroll]);

    const prevRef = React.useRef<HTMLButtonElement>(null)
    const nextRef = React.useRef<HTMLButtonElement>(null)

    return (
      <div
        ref={ref}
        className={cn(carouselVariants({ size, className }))}
        {...props}
      >
        <Button
          ref={prevRef}
          variant="neutralNoShadow"
          size="smIcon"
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 disabled:hidden"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>

        <Button
          ref={nextRef}
          variant="neutralNoShadow"
          size="smIcon"
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 disabled:hidden"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>

        <Swiper
          modules={[Navigation]}
          slidesPerView="auto"
          watchOverflow={true}
          observer={true}
          observeParents={true}
          navigation={{
            enabled: true,
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper: SwiperType) => {
            if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
            }
          }}
          onSwiper={setSwiperInstance}
        >
          {React.Children.map(children, (child, index) => (
            <SwiperSlide key={index} className="!w-auto">
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    )
  }
)

Carousel.displayName = "Carousel"

export { Carousel, type CarouselProps }