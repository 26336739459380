'use client';

import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';
import { cn } from 'src/lib/utils';

interface Option {
  value: string;
  label: string;
}

interface ComboboxTagsProps {
  options?: Option[];
  onChange?: (selected: string[]) => void;
  placeholder?: string;
  className?: string;
  defaultValue?: string[];
}

const MultipleSelector = ({
  options = [],
  onChange = () => {},
  placeholder = 'Select items...',
  className,
  defaultValue = [],
}: ComboboxTagsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>(defaultValue);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Filter options based on input
  const filteredOptions = options.filter(option => 
    option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
    !selectedValues.includes(option.value)
  );

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [inputValue]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (option: Option) => {
    const newValues = [...selectedValues, option.value];
    setSelectedValues(newValues);
    setInputValue('');
    setHighlightedIndex(-1);
    onChange(newValues);
    setIsOpen(false);
    inputRef.current?.focus();
  };

  const handleRemove = (valueToRemove: string) => {
    const newValues = selectedValues.filter(value => value !== valueToRemove);
    setSelectedValues(newValues);
    onChange(newValues);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen && e.key === 'ArrowDown') {
      setIsOpen(true);
      return;
    }

    if (isOpen && filteredOptions.length > 0) {
      switch (e.key) {
        case 'ArrowUp':
          e.preventDefault();
          setHighlightedIndex(prev => 
            prev <= 0 ? filteredOptions.length - 1 : prev - 1
          );
          break;

        case 'ArrowDown':
          e.preventDefault();
          setHighlightedIndex(prev => 
            prev === filteredOptions.length - 1 ? 0 : prev + 1
          );
          break;

        case 'Enter':
          e.preventDefault();
          if (highlightedIndex !== -1) {
            handleSelect(filteredOptions[highlightedIndex]);
          }
          break;

        case 'Escape':
          setIsOpen(false);
          setHighlightedIndex(-1);
          break;
      }
    }
  };

  return (
    <div className={cn("relative w-full", className)} ref={wrapperRef}>
      <div className={cn(
        "min-h-10 flex flex-wrap gap-2 p-2 rounded-md",
        "border border-black bg-gray-900",
        "focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 focus-within:ring-offset-black"
      )}>
        {selectedValues.map((value) => {
          const option = options.find(opt => opt.value === value);
          return (
            <span
              key={value}
              className="flex items-center gap-1.5 px-2 py-1 bg-green rounded-md text-sm text-white shrink-0"
            >
              {option?.label || value}
              <button
                onClick={() => handleRemove(value)}
                className="hover:bg-gray-700 rounded-full p-0.5 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <X className="h-3 w-3" />
              </button>
            </span>
          );
        })}
        
        <input
          ref={inputRef}
          type="text"
          className="flex-1 min-w-[60px] outline-none bg-transparent text-white placeholder:text-gray-400"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            setIsOpen(true);
          }}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsOpen(true)}
          placeholder={selectedValues.length === 0 ? placeholder : ''}
        />
      </div>

      {isOpen && filteredOptions.length > 0 && (
        <div className="absolute w-full mt-1 max-h-60 overflow-auto bg-gray-900 border border-black rounded-md shadow-lg z-10">
          {filteredOptions.map((option, index) => (
            <button
              key={option.value}
              onClick={() => handleSelect(option)}
              onMouseEnter={() => setHighlightedIndex(index)}
              className={cn(
                "w-full px-3 py-2 text-left text-sm text-white hover:bg-gray-800 focus:outline-none",
                index === highlightedIndex && "bg-gray-800"
              )}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

MultipleSelector.displayName = 'MultipleSelector';
export default MultipleSelector;
