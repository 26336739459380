import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAddRecipeToMealMutation } from '../../store/apiSlice';
import { SheetHeader, SheetBody } from '../../components/Sheet';
import RecipeList from '../recipeLibrary/RecipeList';
import { setRecipeLibrarySelectedMealId } from './mealPlanSlice';
import MealSelectorSheetHeader from './MealSelectorSheetHeader';

const RecipeLibrarySheetContent = () => {
  const dispatch = useDispatch();
  const [addRecipeToMeal] = useAddRecipeToMealMutation();
  const selectedMealId = useSelector(state => state.mealPlan.sheets.recipeLibrary.selectedMealId);
  const [additionalActions, setAdditionalActions] = useState([]);

  useEffect(() => {
    if (selectedMealId) {
      setAdditionalActions([{
        text: "Add to Meal",
        handler: async (recipe) => {
          try {
            await addRecipeToMeal({
              meal_id: selectedMealId,
              recipe_id: recipe.recipe_id,
              multiplier: 1,
              recipe: recipe
            });
          } catch (error) {
            console.error('Failed to add recipe to meal:', error);
          }
        }
      }]);
    } else {
      setAdditionalActions([]);
    }
  }, [selectedMealId, addRecipeToMeal]);

  const getSelectedMealId = () => selectedMealId;
  const handleMealChange = (value) => {
    dispatch(setRecipeLibrarySelectedMealId(value));
  };

  return (
    <>
      <SheetHeader>
        <MealSelectorSheetHeader 
          getSelectedMealId={getSelectedMealId}
          onValueChange={handleMealChange}
        />
      </SheetHeader>
      <div className="p-2">
        <RecipeList additionalActions={additionalActions} />
      </div>
    </>
  );
};

export default RecipeLibrarySheetContent;