import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RecipeItem from '../../components/RecipeItem';
import { Carousel } from 'src/components/Carousel';
import UnhydratedRecipeIdea from './UnhydratedRecipeIdea';
import HydratedRecipeIdea from './HydratedRecipeIdea';
import { openAdjustQuantityModal, openCreateGeneratedRecipeSheet } from './mealPlanSlice';
import { useRemoveRecipeFromMealMutation, useGetMealPlanQuery } from '../../store/apiSlice';
import { closeRecipeSheet } from '../../store/globalSlice';

const MealPlanMealRecipes = ({ meal }) => {
  const dispatch = useDispatch();
  const selectedMealIdeaId = useSelector(state => state.mealPlan.selectedMealIdeaId);
  const { data: mealPlan } = useGetMealPlanQuery(meal.meal_plan_id);
  const [removeRecipeFromMeal] = useRemoveRecipeFromMealMutation();
  
  const currentMealIdea = selectedMealIdeaId 
    ? mealPlan?.meal_ideas?.find(idea => 
        idea.id === selectedMealIdeaId && 
        idea.meal_id === meal.id
    ) : null;

  const filteredRecipeIdeas = currentMealIdea?.recipe_ideas?.filter(idea => {
    const recipeId = idea.recipe?.recipe_id;
    return !recipeId || !meal.recipes.some(mealRecipe => 
      mealRecipe.recipe.recipe_id === recipeId
    );
  });

  const handleOpenAdjustQuantityModal = (recipe) => {
    dispatch(openAdjustQuantityModal({
      recipeId: recipe.recipe.recipe_id,
      mealId: meal.id,
      initialMultiplier: recipe.multiplier,
    }));
  };

  const handleRemoveRecipeFromMeal = async (recipeId) => {
    try {
      dispatch(closeRecipeSheet());
      await removeRecipeFromMeal({ meal_id: meal.id, recipe_id: recipeId });
    } catch (error) {
      console.error('Failed to remove recipe from meal:', error);
    }
  };

  const handleOpenGeneratedRecipe = (recipe) => {
    if (!recipe.generated_recipe_id) return;
    
    dispatch(openCreateGeneratedRecipeSheet({
      allowSelectionOfMeal: false,
      selectedMealId: meal.id,
      generatedRecipeId: recipe.generated_recipe_id
    }));
  };

  const getRecipeActions = (recipeData) => ([
    {
      actionText: 'Remove',
      actionHandler: () => handleRemoveRecipeFromMeal(recipeData.recipe.recipe_id)
    },
    {
      actionText: 'Adjust quantity',
      actionHandler: () => handleOpenAdjustQuantityModal(recipeData)
    }
  ]);

  const getSheetActions = (recipeData) => {
    const actions = [
      {
        label: 'Remove',
        onClick: () => handleRemoveRecipeFromMeal(recipeData.recipe.recipe_id)
      },
      {
        label: 'Adjust quantity',
        onClick: () => handleOpenAdjustQuantityModal(recipeData)
      }
    ];

    if (recipeData.recipe.generated_recipe_id) {
      actions.unshift({
        label: 'Edit recipe',
        onClick: () => handleOpenGeneratedRecipe(recipeData.recipe)
      });
    }

    return actions;
  };

  if (meal.published) {
    return (
      <div className="flex flex-col gap-2">
        {meal.recipes.map((recipeData) => (
          <RecipeItem
            key={recipeData.recipe.recipe_id}
            recipe={recipeData.recipe}
            quantity={recipeData.multiplier}
            collapsedView={true}
            actions={getRecipeActions(recipeData)}
            sheetActions={getSheetActions(recipeData)}
            showExternalLink={!recipeData.recipe.generated_recipe_id}
          />
        ))}
      </div>
    );
  }

  return (
    <Carousel autoScroll>
      {meal.recipes.map((recipeData) => (
        <RecipeItem
          key={recipeData.recipe.recipe_id}
          recipe={recipeData.recipe}
          quantity={recipeData.multiplier}
          className="m-1"
          actions={getRecipeActions(recipeData)}
          sheetActions={getSheetActions(recipeData)}
          showExternalLink={!recipeData.recipe.generated_recipe_id}
        />
      ))}
      
      {filteredRecipeIdeas?.map((idea) => (
        <React.Fragment key={idea.id}>
          {!idea.recipe ? (
            <UnhydratedRecipeIdea idea={idea} className="m-1" />
          ) : (
            <HydratedRecipeIdea 
              idea={idea} 
              mealId={meal.id}
              className="m-1"
            />
          )}
        </React.Fragment>
      ))}
    </Carousel>
  );
};

export default MealPlanMealRecipes; 