import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAddExternalRecipeModalOpen: false,
  recipeSheet: {
    isOpen: false,
    recipeData: null,
    sheetActions: [],
    showExternalLink: true
  }
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    openAddExternalRecipeModal: (state) => {
      state.isAddExternalRecipeModalOpen = true;
    },
    closeAddExternalRecipeModal: (state) => {
      state.isAddExternalRecipeModalOpen = false;
    },
    openRecipeSheet: (state, action) => {
      state.recipeSheet.isOpen = true;
      state.recipeSheet.recipeData = action.payload.recipe || action.payload;
      state.recipeSheet.sheetActions = action.payload.actions || [];
      state.recipeSheet.showExternalLink = action.payload.showExternalLink ?? true;
    },
    closeRecipeSheet: (state) => {
      state.recipeSheet.isOpen = false;
      state.recipeSheet.recipeData = null;
      state.recipeSheet.sheetActions = [];
    },
  },
});

export const { 
  openAddExternalRecipeModal, 
  closeAddExternalRecipeModal,
  openRecipeSheet,
  closeRecipeSheet 
} = globalSlice.actions;

export default globalSlice.reducer;
