import * as React from 'react'
import localIcon from '../../assets/cooking_icon.png'

export function MealIdeaSuggestions({ type, data, selectedIndex }) {
  if (type === 'recipe') {
    const hasRating = data.rating_value && data.rating_count;
    const rating = hasRating ? `${Number(data.rating_value).toFixed(1)} (${data.rating_count})` : '';

    return (
      <div className="flex items-center p-1 rounded hover:bg-gray-50 cursor-pointer w-full">
        <img 
          src={data.favicon || localIcon}
          onError={(e) => { e.target.onerror = null; e.target.src = localIcon; }}
          className="w-4 h-4 flex-shrink-0 mr-2"
          alt="Recipe Icon"
        />
        <div className="flex justify-between items-center w-full text-sm">
          <span className="line-clamp-1">{data.headline}</span>
          {hasRating && <span className="ml-4 text-gray-600 flex-shrink-0">{rating}</span>}
        </div>
      </div>
    )
  }

  if (type === 'loading') {
    return (
      <div className="flex items-center p-1 text-sm text-gray-500">
        <div className="animate-spin h-4 w-4 border-2 border-gray-300 rounded-full border-t-gray-600 mr-2"></div>
        <span>Searching...</span>
      </div>
    )
  }

  if (type === 'build') {
    return (
      <div className="flex items-center p-1 rounded hover:bg-gray-50 cursor-pointer w-fit">
        <img 
          src={localIcon}
          className="w-4 h-4 flex-shrink-0 mr-2"
          alt="Action Icon"
        />
        <div className="text-sm line-clamp-1">
          {data.label}
        </div>
      </div>
    )
  }

  if (type === 'custom' || type === 'search') {
    return (
      <div className="text-sm p-1 rounded hover:bg-gray-50 cursor-pointer w-fit">
        {data.label}
      </div>
    )
  }

  return null
} 