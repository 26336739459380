import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { supabase } from '../../lib/supabase-client';
import { setRedirectPath } from '../../store/authSlice';

const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectPath = useSelector((state) => state.auth.redirectPath);

  useEffect(() => {
    // Handle the OAuth callback
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session) {
        navigate(redirectPath, { replace: true });
        // Reset the redirect path to default after successful navigation
        dispatch(setRedirectPath('/schedule'));
      } else {
        navigate('/signin', { replace: true });
      }
    });
  }, [navigate, redirectPath, dispatch]);

  return <div>Loading...</div>;
};

export default AuthCallback; 