import { createSlice } from '@reduxjs/toolkit';

// Helper function to generate unique ID (moved from component)
const generateUniqueId = () => Math.random().toString(36).substr(2, 9);

export const saveStatus = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCESS: 'success',
};

const initialState = {
    groceryList: {},
    toggledRecipes: {},
    isDedupingList: false,
    initialized: false,
    saveStatus: saveStatus.IDLE,
};

const groceryListSlice = createSlice({
    name: 'groceryList',
    initialState,
    reducers: {
        setGroceryList: (state, action) => {
            state.groceryList = action.payload;
            state.initialized = true;
        },
        toggleRecipe: (state, action) => {
            const { recipeId, isToggled } = action.payload;
            state.toggledRecipes[recipeId] = isToggled;
        },
        updateGroceryListItem: (state, action) => {
            const { aisle, itemId, updates } = action.payload;
            const aisleItems = state.groceryList[aisle];
            const itemIndex = aisleItems.findIndex(item => item.item_id === itemId);
            
            if (itemIndex !== -1) {
                if (updates.remove) {
                    state.groceryList[aisle] = [
                        ...aisleItems.slice(0, itemIndex),
                        ...aisleItems.slice(itemIndex + 1)
                    ];
                } else {
                    state.groceryList[aisle][itemIndex] = {
                        ...state.groceryList[aisle][itemIndex],
                        ...updates
                    };
                }
            }
        },
        addGroceryListItem: (state, action) => {
            const { aisle } = action.payload;
            
            // Create new item with default values
            const newItem = {
                item_id: generateUniqueId(),
                checkbox_status: 'unchecked',
                ingredient_string: '',
                recipe_id: null
            };

            if (!state.groceryList[aisle]) {
                state.groceryList[aisle] = [];
            }
            state.groceryList[aisle].push(newItem);
        },
        shiftCheckboxStatus: (state, action) => {
            const { aisle, itemId } = action.payload;
            const aisleItems = state.groceryList[aisle];
            const itemIndex = aisleItems.findIndex(item => item.item_id === itemId);

            if (itemIndex !== -1) {
                const currentStatus = aisleItems[itemIndex].checkbox_status;
                
                // Status cycles: unchecked -> checked -> confirm -> unchecked
                const statusMap = {
                    'unchecked': 'checked',
                    'checked': 'confirm',
                    'confirm': 'unchecked'
                };

                state.groceryList[aisle][itemIndex].checkbox_status = statusMap[currentStatus];
            }
        },
        splitGroceryListItem: (state, action) => {
            const { aisle, itemId, cursorPosition } = action.payload;
            const aisleItems = state.groceryList[aisle];
            const itemIndex = aisleItems.findIndex(item => item.item_id === itemId);
            
            if (itemIndex !== -1) {
                const currentItem = aisleItems[itemIndex];
                const text = currentItem.ingredient_string;
                
                // Create two new items with split text
                const firstItem = {
                    item_id: generateUniqueId(),
                    checkbox_status: 'unchecked',
                    ingredient_string: text.slice(0, cursorPosition),
                    recipe_id: null
                };
                
                const secondItem = {
                    item_id: generateUniqueId(),
                    checkbox_status: 'unchecked',
                    ingredient_string: text.slice(cursorPosition),
                    recipe_id: null
                };
                
                // Replace the original item with the two new items
                state.groceryList[aisle] = [
                    ...aisleItems.slice(0, itemIndex),
                    firstItem,
                    secondItem,
                    ...aisleItems.slice(itemIndex + 1)
                ];
            }
        },
        mergeWithPreviousItem: (state, action) => {
            const { aisle, itemId } = action.payload;
            const aisleItems = state.groceryList[aisle];
            const itemIndex = aisleItems.findIndex(item => item.item_id === itemId);
            
            if (itemIndex > 0) { // Ensure there's a previous item
                const currentItem = aisleItems[itemIndex];
                const previousItem = aisleItems[itemIndex - 1];
                
                // Merge the text
                previousItem.ingredient_string += currentItem.ingredient_string;
                
                // Remove the current item
                state.groceryList[aisle] = [
                    ...aisleItems.slice(0, itemIndex),
                    ...aisleItems.slice(itemIndex + 1)
                ];
            }
        },
        setDedupingStatus: (state, action) => {
            state.isDedupingList = action.payload;
        },
        setSaveStatus: (state, action) => {
            state.saveStatus = action.payload;
        },
        resetInitialized: (state) => {
            state.initialized = false;
        },
    }
});

export const { 
    setGroceryList, 
    toggleRecipe, 
    updateGroceryListItem,
    addGroceryListItem,
    shiftCheckboxStatus,
    splitGroceryListItem,
    mergeWithPreviousItem,
    setDedupingStatus,
    setSaveStatus,
    resetInitialized
} = groceryListSlice.actions;

export default groceryListSlice.reducer;

export const selectFilteredGroceryList = (state) => {
    const { groceryList, toggledRecipes } = state.groceryList;
    
    const activeRecipes = Object.entries(toggledRecipes)
        .filter(([_, isToggled]) => isToggled)
        .map(([recipeId]) => recipeId);

    if (activeRecipes.length === 0) {
        return groceryList;
    }

    return Object.entries(groceryList).reduce((filtered, [aisle, items]) => {
        filtered[aisle] = items.filter(item => 
            !item.recipe_id || activeRecipes.includes(item.recipe_id)
        );
        return filtered;
    }, {});
};
