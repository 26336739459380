import React from 'react';
import { Button } from '../../components/Button';
import MealSelectorSheetHeader from './MealSelectorSheetHeader';

const CreateGeneratedRecipeHeader = ({ 
  allowSelectionOfMeal,
  getSelectedMealId,
  handleMealChange,
  handleSubmit,
  selectedMealId,
  currentActiveRecipe,
  isRecipeAlreadyInMeal
}) => {
  if (allowSelectionOfMeal) {
    return (
      <div className="flex flex-col gap-4">
        <MealSelectorSheetHeader 
          getSelectedMealId={getSelectedMealId}
          onValueChange={handleMealChange}
        />
        <Button 
          variant="default"
          onClick={handleSubmit}
          disabled={selectedMealId === null || 
            currentActiveRecipe === null || 
            isRecipeAlreadyInMeal}
          className="w-fit"
        >
          Add recipe to meal
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-wrap justify-between items-center gap-4">
      <div className="text-xl font-semibold">Update recipe</div>
      <Button 
        variant="default"
        onClick={handleSubmit}
        disabled={selectedMealId === null || 
          currentActiveRecipe === null || 
          isRecipeAlreadyInMeal}
        className="w-fit"
      >
        Add to meal
      </Button>
    </div>
  );
};

export default CreateGeneratedRecipeHeader; 