import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../utils';
import RecipeItem from '../../components/RecipeItem';
import { openAdjustQuantityModal, updateMealNote, setSelectedMealIdeaId, openCreateGeneratedRecipeSheet } from './mealPlanSlice';
import { useRemoveRecipeFromMealMutation, useGetMealPlanQuery, usePublishMealsMutation, useAddRecipeToMealMutation } from '../../store/apiSlice';
import { Badge } from 'src/components/Badge';
import { useGetUserQuery } from '../../store/apiSlice';
import NutritionTable from './NutritionTable';
import { Input } from '../../components/Input';
import { Carousel } from 'src/components/Carousel';
import ToggleableMealIdea from './ToggleableMealIdea';
import { Toggle } from '../../components/Toggle';
import { Check } from 'lucide-react';
import { closeRecipeSheet } from '../../store/globalSlice';
import MealIdeaCarousel from './MealIdeaCarousel';
import UnhydratedRecipeIdea from './UnhydratedRecipeIdea';
import HydratedRecipeIdea from './HydratedRecipeIdea';
import MealPlanMealRecipes from './MealPlanMealRecipes';
import { MealIdeaInput } from './MealIdeaInput';
import { setExternalSearchRecipeSelectedMealId, setExternalSearchRecipeSearchTerm, openExternalSearchRecipeSheet } from './mealPlanSlice';

const MealPlanMeal = ({ meal, leftovers }) => {
  const dispatch = useDispatch();
  const showParticipants = useSelector(state => state.mealPlan.showParticipants);
  const showNutrition = useSelector(state => state.mealPlan.showNutrition);
  const { meal_type, recipes, id: mealId } = meal;
  const { data: user } = useGetUserQuery();
  const { data: mealPlan } = useGetMealPlanQuery(meal.meal_plan_id);
  const householdDict = user?.household?.reduce((acc, member) => {
      acc[member.id] = member;
      return acc;
  }, {}) || {};
  const mealNote = useSelector(state => state.mealPlan.mealNotes[meal.id] || '');
  const [publishMeals] = usePublishMealsMutation();
  const [addRecipeToMeal] = useAddRecipeToMealMutation();
  
  // Use meal ideas from the meal plan query
  const mealSpecificIdeas = useMemo(() => {
    const ideas = mealPlan?.meal_ideas?.filter(idea => idea.meal_id === meal.id) || [];
    return ideas;
  }, [mealPlan, meal.id]);

  const handleNoteChange = (value) => {
    dispatch(updateMealNote({
      mealId: meal.id,
      note: value
    }));
  };

  const renderRecipients = () => {
    if (!showParticipants || !meal.meal_recipients?.length) {
      return null;
    }

    const recipientNames = meal.meal_recipients
      .map(recipientId => householdDict[recipientId]?.customer_facing_name)
      .filter(name => name)
      .join(', ');

    return recipientNames ? (
      <span className="text-xs">Meal for: {recipientNames}</span>
    ) : null;
  };

  const handlePublishToggle = async () => {
    try {
      await publishMeals({
        mealIds: [meal.id],
        published: !meal.published
      });
    } catch (error) {
      console.error('Failed to toggle meal publication:', error);
      // Optionally add error handling UI feedback here
    }
  };

  // Add these handlers for MealIdeaInput
  const handleRecipeSelect = async (recipe) => {
    try {
      await addRecipeToMeal({
        meal_id: meal.id,
        recipe_id: recipe.recipe_id,
        multiplier: 1,
        recipe: recipe // Pass full recipe for optimistic updates
      });
    } catch (error) {
      console.error('Failed to add recipe to meal:', error);
    }
  };

  const handleSearchOpen = (_, searchTerm) => {
    dispatch(setExternalSearchRecipeSelectedMealId(meal.id));
    dispatch(setExternalSearchRecipeSearchTerm(searchTerm));
    dispatch(openExternalSearchRecipeSheet());
  };

  const handleBuildOpen = (_, recipeName) => {
    dispatch(openCreateGeneratedRecipeSheet({ 
      selectedMealId: meal.id,
      allowSelectionOfMeal: false,
      currentPrompt: recipeName
    }));
  };

  // Early return for primary meals
  if (meal.primary_meal) {
    return (
      <div className="max-w-full pb-4">
        <div className="flex flex-col gap-2">
          <div className="text-lg">{meal_type.charAt(0).toUpperCase() + meal_type.slice(1)}</div>
          {renderRecipients()}
          <Badge variant="destructive" className="w-fit">Leftover for {formatDate(meal.primary_meal.date)}'s {meal.primary_meal.meal_type}</Badge>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-full pb-4">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <div className="text-lg">{meal_type.charAt(0).toUpperCase() + meal_type.slice(1)}</div>
          {(recipes.length > 0 || meal.published) && (
            <Toggle
              size="sm"
              onPressedChange={handlePublishToggle}
              pressed={meal.published}
              aria-label="Toggle meal publication"
            >
              <Check className="h-3 w-3" />
            </Toggle>
          )}
        </div>
        {renderRecipients()}
        {!meal.published && (
          <MealIdeaInput
            placeholder={`Any notes for ${meal_type} or type "/" if you know what you want`}
            value={mealNote}
            onChange={handleNoteChange}
            recipeHandler={handleRecipeSelect}
            searchHandler={handleSearchOpen}
            buildHandler={handleBuildOpen}
            className="text-sm"
          />
        )}
        {showNutrition && (
          <NutritionTable 
            meal={meal}
            leftovers={leftovers}
            recipes={recipes}
            householdDict={householdDict}
          />
        )}
        
        <MealPlanMealRecipes meal={meal} />
        
        {!meal.published && mealSpecificIdeas.length > 0 && (
          <MealIdeaCarousel 
            mealSpecificIdeas={mealSpecificIdeas}
            mealId={meal.id}
          />
        )}
      </div>
    </div>
  );
};

export default MealPlanMeal;
