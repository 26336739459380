"use client"

import * as React from "react"
import * as Select from "@radix-ui/react-select"
import { Check, ChevronDown } from "lucide-react"
import { cn } from "src/lib/utils"
import { cva, VariantProps } from "class-variance-authority"

const multiSelectTriggerVariants = cva(
  "flex w-full items-center justify-between rounded-md text-black font-base selection:bg-primary selection:text-white px-3 py-2 text-sm ring-offset-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "border-black bg-primary border",
        neutral: "bg-gray-300 text-black border-black hover:bg-gray-200 border",
        text: "bg-transparent border-none",
      },
      size: {
        default: "h-10",
        multiselect: "h-12",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface SimpleMultiSelectProps extends VariantProps<typeof multiSelectTriggerVariants> {
  options: { value: string; label: string }[]
  value: string[]
  onChange: (value: string[]) => void
  placeholder?: string
  allSelectedText?: string
  className?: string
}

export const SimpleMultiSelect: React.FC<SimpleMultiSelectProps> = ({
  options,
  value,
  onChange,
  placeholder = "Select items...",
  allSelectedText = "All items selected",
  className,
  variant,
  size,
}) => {
  const [open, setOpen] = React.useState(false)

  const handleValueChange = (optionValue: string) => {
    const newValue = value.includes(optionValue) ? value.filter((v) => v !== optionValue) : [...value, optionValue]
    onChange(newValue)
  }

  const getDisplayValue = () => {
    if (value.length === 0) return placeholder
    if (value.length === options.length) return allSelectedText
    if (value.length === 1) return options.find((option) => option.value === value[0])?.label
    return `${value.length} selected`
  }

  return (
    <div className="relative">
      <div
        className={cn(multiSelectTriggerVariants({ variant, size, className }))}
        onClick={() => setOpen(!open)}
      >
        <div className="flex-1 truncate">{getDisplayValue()}</div>
        <ChevronDown className="h-4 w-4 opacity-50" />
      </div>
      {open && (
        <div className="absolute z-50 w-full mt-1 rounded-md border-2 border-white bg-gray-200 text-black shadow-md overflow-hidden">
          {options.map((option) => (
            <div
              key={option.value}
              className={cn(
                "relative flex w-full cursor-default select-none items-center py-1.5 pl-8 pr-2 text-sm outline-none",
                "hover:bg-black/5 focus:bg-white/10 focus:text-white",
                value.includes(option.value) && "bg-black/5"
              )}
              onClick={() => handleValueChange(option.value)}
            >
              <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                {value.includes(option.value) && <Check className="h-4 w-4" />}
              </span>
              <div className="flex-1">{option.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

