import React from 'react';

export default function DeduplicationToast({ edit }) {
    return (
        <div>
            <p className="text-sm mb-1">
                <span className="font-semibold">Combining ingredients:</span> {edit.duplicate_ingredients.map(i => i.ingredient_string).join(", ")}
            </p>
            <p className="text-sm mb-1">
                <span className="font-semibold">Into:</span> {edit.new_item.ingredient_string}
            </p>
        </div>
    );
} 