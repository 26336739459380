import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { SaveStatus } from '../groceryList/SaveStatus';
import { useGroceryListActions } from '../groceryList/hooks/useGroceryListActions';
import { useGetMealPlanQuery } from '../../store/apiSlice';

const GroceryListControlPanel = () => {
    const mealPlanId = useSelector(state => state.mealPlan.meal_plan_id);
    const { data: mealPlan } = useGetMealPlanQuery(mealPlanId);
    const groceryListId = mealPlan?.grocery_list_id;
    
    const groceryList = useSelector(state => state.groceryList.groceryList);
    const isDedupingList = useSelector(state => state.groceryList.isDedupingList);
    
    const { handleDeduplicate } = useGroceryListActions(groceryListId, groceryList);

    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
                <SaveStatus />
            </div>
            <div className="flex gap-2">
                <Button 
                    variant="noShadow"
                    size="sm"
                    onClick={handleDeduplicate} 
                    disabled={isDedupingList}
                >
                    {isDedupingList ? 'Deduplicating...' : 'Deduplicate list'}
                </Button>
            </div>
        </div>
    );
};

export default GroceryListControlPanel;