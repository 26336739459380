import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGetUserQuery, useListMealsQuery, useClearMealPlanHistoryMutation } from '../../store/apiSlice';
import { Button } from '../../components/Button';
import { SmallButtonGroup, SmallGroupToggle, SmallGroupButton } from '../../components/SmallButtonGroup';
import { Table2, Users, Microwave, X, Globe, ChefHat, Library } from 'lucide-react';
import {
    toggleShowNutrition,
    toggleShowParticipants,
    toggleShowLeftovers,
    openRecipeLibrarySheet,
    openCreateGeneratedRecipeSheet,
    openExternalSearchRecipeSheet,
    openMealPlanningPreferencesSheet,
} from './mealPlanSlice';

const MealPlanMealPlanControlPanel = () => {
    const dispatch = useDispatch();
    const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
    const showNutrition = useSelector(state => state.mealPlan.showNutrition);
    const showParticipants = useSelector(state => state.mealPlan.showParticipants);
    const showLeftovers = useSelector(state => state.mealPlan.showLeftovers);
    const mealPlanStreaming = useSelector(state => state.mealPlan.mealPlanStreaming);

    const { data: user } = useGetUserQuery();
    const { data: mealsData } = useListMealsQuery({ mealPlanId: meal_plan_id });
    const [clearMealPlanHistory] = useClearMealPlanHistoryMutation();

    const mealPlanningPreferencesCount = user?.preferences?.meal_planning_ideas?.length || 0;
    
    const hasLeftovers = React.useMemo(() => {
        if (!mealsData?.meals) return false;
        return mealsData.meals.some(meal => meal.primary_meal !== null);
    }, [mealsData]);

    const activeToggles = [
        ...(showNutrition ? ['table'] : []),
        ...(showParticipants ? ['users'] : []),
        ...(showLeftovers ? ['microwave'] : []),
    ];

    const handleTogglesChange = useCallback((newToggles) => {
        if (newToggles.includes('table') !== showNutrition) {
            dispatch(toggleShowNutrition());
        }
        if (newToggles.includes('users') !== showParticipants) {
            dispatch(toggleShowParticipants());
        }
        if (newToggles.includes('microwave') !== showLeftovers) {
            dispatch(toggleShowLeftovers());
        }
    }, [dispatch, showNutrition, showParticipants, showLeftovers]);

    const handleClearHistory = useCallback(async () => {
        try {
            await clearMealPlanHistory(meal_plan_id);
        } catch (error) {
            console.error('Error clearing meal plan history:', error);
        }
    }, [clearMealPlanHistory, meal_plan_id]);

    return (
        <div className="flex items-center space-x-4">
            <Button 
                variant="link" 
                size="sm" 
                disabled={mealPlanStreaming}
                onClick={() => dispatch(openMealPlanningPreferencesSheet())}
            >
                {mealPlanningPreferencesCount === 0 ? "No notes yet" : `Using ${mealPlanningPreferencesCount} ${mealPlanningPreferencesCount === 1 ? 'note' : 'notes'}`}
            </Button>

            <SmallButtonGroup 
                type="multiple" 
                value={activeToggles}
                disabled={mealPlanStreaming}
                onValueChange={handleTogglesChange}
            >
                <SmallGroupToggle value="table" aria-label="Toggle table view">
                    <Table2 className="h-4 w-4" />
                </SmallGroupToggle>
                <SmallGroupToggle value="users" aria-label="Toggle users view">
                    <Users className="h-4 w-4" />
                </SmallGroupToggle>
                {hasLeftovers && (
                    <SmallGroupToggle value="microwave" aria-label="Toggle leftovers view">
                        <Microwave className="h-4 w-4" />
                    </SmallGroupToggle>
                )}
                <SmallGroupButton onClick={handleClearHistory} aria-label="Clear history">
                    <X className="h-4 w-4" />
                </SmallGroupButton>
            </SmallButtonGroup>
        </div>
    );
};

export default MealPlanMealPlanControlPanel;