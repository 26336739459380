// src/components/AddExternalRecipe.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogClose } from 'src/components/Dialog';
import { Button } from 'src/components/Button';
import { Input } from 'src/components/Input';
import { useAddExternalRecipeMutation, useValidateUrlMutation } from 'src/store/apiSlice';
import { Badge } from 'src/components/Badge';
import { closeAddExternalRecipeModal } from 'src/store/globalSlice';

export default function AddExternalRecipe() {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state.global.isAddExternalRecipeModalOpen);
    const user = useSelector((state) => state.auth.user);
    
    const [recipeUrl, setRecipeUrl] = useState('');
    const [isAdding, setIsAdding] = useState(false);
    const [addingMessage, setAddingMessage] = useState('Fetching recipe information...');
    const [urlError, setUrlError] = useState('');
    const [favicon, setFavicon] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [isUrlValid, setIsUrlValid] = useState(false);

    const [addExternalRecipe] = useAddExternalRecipeMutation();
    const [validateUrl, { isLoading: isValidating }] = useValidateUrlMutation();

    useEffect(() => {
        // Debugging: Log when the modal opens or closes
        console.log('AddExternalRecipe modal isOpen:', isOpen);
        
        // Cleanup function to ensure we remove any leftover styles or elements
        return () => {
            document.body.style.pointerEvents = '';
            const overlay = document.querySelector('[data-radix-portal]');
            if (overlay) {
                overlay.remove();
            }
        };
    }, [isOpen]);

    const handleUrlChange = async (e) => {
        const url = e.target.value;
        setRecipeUrl(url);
        setUrlError('');
        setFavicon('');
        setPageTitle('');
        setIsUrlValid(false);

        if (url) {
            try {
                const result = await validateUrl(url).unwrap();
                setFavicon(result.favicon_url);
                setPageTitle(result.title);
                setIsUrlValid(true);
            } catch (error) {
                if (error.status === 400) {
                    setUrlError('Invalid URL format');
                } else if (error.status === 422) {
                    setUrlError('This domain is not allowed');
                } else {
                    setUrlError('Error validating URL');
                }
                setIsUrlValid(false);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isUrlValid) return;

        setIsAdding(true);
        try {
            const response = await addExternalRecipe({ url: recipeUrl });
            if ('data' in response) {
                dispatch(closeAddExternalRecipeModal());
            } else if ('error' in response) {
                console.error('Error adding recipe:', response.error);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsAdding(false);
        }
    };

    const handleClose = () => {
        console.log('handleClose called');
        setRecipeUrl('');
        setUrlError('');
        setFavicon('');
        setPageTitle('');
        setIsUrlValid(false);
        setIsAdding(false);
        dispatch(closeAddExternalRecipeModal());
        
        // Force removal of any potential leftover overlay
        setTimeout(() => {
            const overlay = document.querySelector('[data-radix-portal]');
            if (overlay) {
                overlay.remove();
            }
            document.body.style.pointerEvents = '';
        }, 100);
    };

    if (!isOpen) return null; // Don't render anything if the modal is closed

    return (
        <Dialog open={isOpen} onOpenChange={handleClose}>
            <DialogContent className="z-50"> {/* Ensure high z-index */}
                <DialogHeader>
                    <DialogTitle>Add External Recipe</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className="label">
                            <span className="label-text text-black">Recipe URL</span>
                        </label>
                        <Input 
                            type="text" 
                            placeholder="Enter the URL of the recipe" 
                            name="url" 
                            value={recipeUrl} 
                            onChange={handleUrlChange} 
                            className="mb-4"
                        />
                        {urlError && (
                            <Badge variant="destructive" className="mt-2">
                                {urlError}
                            </Badge>
                        )}
                        {favicon && pageTitle && (
                            <div className="flex items-center mt-2">
                                <img src={favicon} alt="Favicon" className="w-4 h-4 mr-2" />
                                <span className="text-sm">{pageTitle}</span>
                            </div>
                        )}
                    </div>
                    <DialogFooter>
                        {!isAdding && (
                            <Button 
                                type="submit" 
                                disabled={!isUrlValid || isValidating}
                            >
                                {isValidating ? 'Validating...' : 'Add recipe'}
                            </Button>
                        )}
                        {isAdding && (
                            <div className="py-2">
                                <p className="text-sm">
                                    <span className="loading loading-dots loading-lg"></span>
                                    {addingMessage}
                                </p>
                            </div>
                        )}
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}