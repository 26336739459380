import * as React from 'react'
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from 'src/lib/utils'

const inputVariants = cva(
  'flex w-full rounded-md text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-80',
  {
    variants: {
      variant: {
        default: [
          'bg-gray-900 px-3 py-2',
          'text-white font-base',
          'selection:bg-primary selection:text-white',
          'focus-visible:outline-none',
          'placeholder:text-gray-300',
        ],
        clean: [
          'bg-transparent',
          'text-gray-800',
          'border-none',
          'focus-visible:outline-none',
          'selection:bg-primary selection:text-white',
          'placeholder:text-gray-400',
        ],
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input, inputVariants }