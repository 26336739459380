import React from 'react';
import { useSelector } from 'react-redux';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import RecipeFull from '../../components/RecipeFull';
import RecipeVersionNavigation from './RecipeVersionNavigation';

const RecipeGenerationRecipePreview = () => {
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const selectedVersion = useSelector((state) => state.recipeGeneration.selectedVersion);
    
    const { data, error, isLoading } = useGetGeneratedRecipeQuery(generatedRecipeId, {
        skip: !generatedRecipeId,
    });

    if (!data || !data?.recipes || isLoading || !generatedRecipeId) {
        return null;
    }

    if (error) {
        console.error("Error fetching generated recipe:", error);
        return null;
    }

    const recipeVersions = Object.values(data.recipes);

    return (
        <div className="h-full overflow-y-auto p-4">
            {recipeVersions.length > 1 && (
                <RecipeVersionNavigation versions={recipeVersions} />
            )}
            <RecipeFull 
                recipe={recipeVersions[selectedVersion]}
                showExternalLink={false}
            />
        </div>
    );
};

export default RecipeGenerationRecipePreview;