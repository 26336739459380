import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetGroceryListQuery, useUpdateGroceryListMutation, useRefreshGroceryListMutation, useDeduplicateGroceryListMutation } from 'src/store/apiSlice';
import { setGroceryList, toggleRecipe, selectFilteredGroceryList, setDedupingStatus, setSaveStatus, saveStatus, resetInitialized } from './groceryListSlice';
import { ToggleableCard } from '../../components/ToggleableCard';
import { Button } from '../../components/Button';
import { GroceryListAisle } from './GroceryListAisle';
import { toast } from 'sonner';
import DeduplicationToast from './DeduplicationToast';
import { SaveStatus } from './SaveStatus';
import debounce from 'lodash/debounce';
import { useGroceryListActions } from './hooks/useGroceryListActions';

export default function GroceryList({ groceryListId, hideControls = false, onDeduplicate }) {
    const aisles = ["produce", "butcher", "frozen foods", "dairy", "bakery", "ethnic", "other"];
    const dispatch = useDispatch();
    
    const groceryList = useSelector(state => state.groceryList.groceryList);
    const isDedupingList = useSelector(state => state.groceryList.isDedupingList);
    const filteredGroceryList = useSelector(selectFilteredGroceryList);
    const initialized = useSelector(state => state.groceryList.initialized);
    
    const { 
        data: groceryListResponse, 
        isLoading, 
        isError 
    } = useGetGroceryListQuery(groceryListId);
    
    const [updateGroceryList] = useUpdateGroceryListMutation();
    const [refreshGroceryList] = useRefreshGroceryListMutation();
    const [deduplicateGroceryList] = useDeduplicateGroceryListMutation();

    const { handleDeduplicate } = useGroceryListActions(groceryListId, groceryList);

    // Use the passed handler if available, otherwise use local handler
    const deduplicateHandler = onDeduplicate || handleDeduplicate;

    const debouncedSave = React.useCallback(
        debounce(async (groceryList) => {
            try {
                await updateGroceryList({ groceryListId, groceryList }).unwrap();
                dispatch(setSaveStatus(saveStatus.SUCCESS));
                // Hide the success status after 2 seconds
                setTimeout(() => {
                    dispatch(setSaveStatus(saveStatus.IDLE));
                }, 1000);
            } catch (error) {
                console.error('Failed to save:', error);
                toast.error('Failed to save grocery list');
                dispatch(setSaveStatus(saveStatus.IDLE));
            }
        }, 1000),
        [groceryListId, updateGroceryList]
    );

    // Watch for changes and trigger save
    useEffect(() => {
        if (initialized && groceryList) {
            dispatch(setSaveStatus(saveStatus.PENDING));
            debouncedSave(groceryList);
        }
    }, [groceryList, initialized, debouncedSave]);

    // Add cleanup effect
    useEffect(() => {
        return () => {
            dispatch(resetInitialized());
            debouncedSave.cancel();
        };
    }, [debouncedSave, dispatch]);

    const isStale = groceryListResponse?.stale;

    const handleRecipeToggle = (recipeId) => (isToggled) => {
        dispatch(toggleRecipe({ recipeId, isToggled }));
    };

    useEffect(() => {
        if (groceryListResponse && !initialized) {
            const groceryListData = groceryListResponse.grocery_list;
            const updatedGroceryList = aisles.reduce((acc, aisle) => {
                acc[aisle] = groceryListData[aisle] || [];
                return acc;
            }, {});
            dispatch(setGroceryList(updatedGroceryList));
        }
    }, [groceryListResponse, dispatch, aisles, initialized]);
    
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading grocery list.</div>;

    const handleSave = () => {
        updateGroceryList({ groceryListId, groceryList });
    };

    const handleRefresh = () => {
        refreshGroceryList({ groceryListId });
    };



    const handleAisleNavigation = (currentAisle, itemId, direction, cursorPosition) => {
        const currentAisleIndex = aisles.indexOf(currentAisle);
        
        const findNextValidAisle = (startIndex, step) => {
            let index = startIndex;
            while (index >= 0 && index < aisles.length) {
                const aisle = aisles[index];
                const aisleItems = filteredGroceryList[aisle] || [];
                if (aisleItems.length > 0) {
                    return aisle;
                }
                index += step;
            }
            return null;
        };

        if (direction === 'up' && currentAisleIndex > 0) {
            const prevAisle = findNextValidAisle(currentAisleIndex - 1, -1);
            if (prevAisle) {
                const prevAisleItems = filteredGroceryList[prevAisle] || [];
                const lastItemId = prevAisleItems[prevAisleItems.length - 1]?.item_id;
                if (lastItemId) {
                    const input = document.querySelector(`[data-item-id="${lastItemId}"] input`);
                    input?.focus();
                    input?.setSelectionRange(cursorPosition, cursorPosition);
                    return true;
                }
            }
        } else if (direction === 'down' && currentAisleIndex < aisles.length - 1) {
            const nextAisle = findNextValidAisle(currentAisleIndex + 1, 1);
            if (nextAisle) {
                const nextAisleItems = filteredGroceryList[nextAisle] || [];
                const firstItemId = nextAisleItems[0]?.item_id;
                if (firstItemId) {
                    const input = document.querySelector(`[data-item-id="${firstItemId}"] input`);
                    input?.focus();
                    input?.setSelectionRange(cursorPosition, cursorPosition);
                    return true;
                }
            }
        }
        return false;
    };

    return (
        <>
            {!hideControls && (
                <div className="flex justify-between items-baseline pt-16 pb-4">
                    <div className="flex items-center">
                        <h1 className="text-4xl font-bold mr-4">Grocery List</h1>
                        <SaveStatus />
                        {isStale && (
                            <Button onClick={handleRefresh} variant="link">
                                Grocery list is stale, refresh grocery list
                            </Button>
                        )}
                    </div>
                    <div className="flex gap-2">
                        <Button 
                            onClick={deduplicateHandler} 
                            disabled={isDedupingList}
                        >
                            {isDedupingList ? 'Deduplicating...' : 'Deduplicate'}
                        </Button>
                    </div>
                </div>
            )}

            {/* Recipe Toggle Cards */}
            {groceryListResponse?.recipes && Object.values(groceryListResponse.recipes).length > 0 && (
                <div className="mb-6">
                    <h2 className="text-2xl font-semibold mb-2">Recipes</h2>
                    <div className="flex flex-wrap gap-2">
                        {Object.values(groceryListResponse.recipes).map((recipe) => (
                            <ToggleableCard 
                                key={recipe.recipe_id}
                                className="w-48"
                                onToggle={handleRecipeToggle(recipe.recipe_id)}
                            >
                                {recipe.headline}
                            </ToggleableCard>
                        ))}
                    </div>
                </div>
            )}

            {aisles.map((aisle) => (
                <GroceryListAisle
                    key={aisle}
                    aisle={aisle}
                    onAisleNavigation={handleAisleNavigation}
                />
            ))}
        </>
    );
}