"use client"

import { Toaster as Sonner } from "sonner"
import { cn } from "src/lib/utils"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group"
      toastOptions={{
        classNames: {
          toast: cn(
            "group toast",
            "bg-white border border-black rounded",
            "shadow-dark-gray",
            "transition-all"
          ),
          description: "text-gray-600",
          actionButton: cn(
            "bg-primary",
            "border border-black",
            "text-black",
            "hover:translate-x-boxShadowX hover:translate-y-boxShadowY",
            "hover:shadow-none"
          ),
          cancelButton: cn(
            "bg-gray-300",
            "border border-black",
            "text-black",
            "hover:translate-x-boxShadowX hover:translate-y-boxShadowY",
            "hover:shadow-none"
          ),
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
