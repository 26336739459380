import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeBackendRequest } from '../../api';
import moment from 'moment';

export default function GroceryList() {
    const [groceryLists, setGroceryLists] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            fetchGroceryLists();
        }
    }, [user]);

    const fetchGroceryLists = () => {
        makeBackendRequest('/list_grocery_lists', 'get')
            .then(response => {
                const sortedLists = response.data.grocery_lists.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setGroceryLists(sortedLists);
            })
            .catch(error => console.error(error));
    };

    const handleRowClick = (listId) => {
        navigate(`/grocery_list/${listId}`);
    };

    return (
        <div className="max-w-[600px] mx-auto px-4 py-8">
            <h1 className="text-4xl font-bold mb-6">Grocery Lists</h1>
            <div className="overflow-x-auto">
                <table className="table w-full">
                    <thead>
                        <tr>
                            <th className="text-gray-300">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {groceryLists.map(list => (
                            <tr key={list.id} onClick={() => handleRowClick(list.id)} className="cursor-pointer hover:bg-gray-100 capitalize">
                                <td>{moment(list.created_at).fromNow()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}