import React from 'react';
import { useListMealsQuery } from '../../store/apiSlice';
import { useSelector } from 'react-redux';
import { RadioGroup, RadioGroupItem } from '../../components/Radio';
import { Label } from '../../components/Label';
import { formatDate, sortMeals } from '../../utils';

const MealSelectorSheetHeader = ({ getSelectedMealId, onValueChange }) => {
  const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
  const { data, error, isLoading } = useListMealsQuery({ mealPlanId: meal_plan_id });

  const getRecipesList = (recipes) => {
    if (!recipes || recipes.length === 0) {
      return "No recipes scheduled yet";
    }
    return recipes.map(recipe => recipe.recipe.headline).join(", ");
  };

  const getMealName = (meal) => {
    const formattedDate = formatDate(meal.date);
    const mealType = meal.meal_type.charAt(0).toUpperCase() + meal.meal_type.slice(1);
    return `${formattedDate} - ${mealType}`;
  };

  const filteredAndSortedMeals = React.useMemo(() => {
    const meals = data?.meals || [];
    if (!meals) return [];
    return sortMeals(meals.filter(meal => meal.primary_meal === null));
  }, [data]);

  return (
    <>
      <div className="text-lg font-bold mb-4">Select a meal</div>
      {isLoading ? (
        <div>Loading meals...</div>
      ) : error ? (
        <div>Error loading meals: {error.message}</div>
      ) : (
        <RadioGroup 
          value={getSelectedMealId()} 
          onValueChange={onValueChange}
          className="flex flex-wrap gap-4"
        >
          {filteredAndSortedMeals.map((meal) => (
            <div key={meal.id} className="flex flex-col space-y-1 min-w-xs">
              <div className="flex items-center space-x-2">
                <RadioGroupItem value={meal.id} id={meal.id} />
                <Label htmlFor={meal.id} className="font-bold">
                  {getMealName(meal)}
                </Label>
              </div>
              <span className="text-sm text-gray-600 ml-7">
                {getRecipesList(meal.recipes)}
              </span>
            </div>
          ))}
        </RadioGroup>
      )}
    </>
  );
};

export default MealSelectorSheetHeader;