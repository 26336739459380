import { useDispatch } from 'react-redux';
import { useUpdateGroceryListMutation, useDeduplicateGroceryListMutation } from '../../../store/apiSlice';
import { setGroceryList, setDedupingStatus } from '../groceryListSlice';
import { toast } from 'sonner';
import DeduplicationToast from '../DeduplicationToast';

export const useGroceryListActions = (groceryListId, groceryList) => {
    const dispatch = useDispatch();
    const [updateGroceryList] = useUpdateGroceryListMutation();
    const [deduplicateGroceryList] = useDeduplicateGroceryListMutation();

    const handleDeduplicate = async () => {
        try {
            dispatch(setDedupingStatus(true));
            
            // First save the current grocery list
            await updateGroceryList({ groceryListId, groceryList }).unwrap();
            
            // Then proceed with deduplication
            const response = await deduplicateGroceryList(groceryListId).unwrap();
            
            response.edits.forEach(edit => {
                toast(<DeduplicationToast edit={edit} />);
            });

            dispatch(setGroceryList(response.updated_grocery_list));
        } catch (error) {
            console.error('Failed to save or deduplicate:', error);
            toast.error('Failed to deduplicate ingredients');
        } finally {
            dispatch(setDedupingStatus(false));
        }
    };

    return {
        handleDeduplicate
    };
};