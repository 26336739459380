import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SheetHeader, SheetBody } from '../../components/Sheet';
import MealSelectorSheetHeader from './MealSelectorSheetHeader';
import RecipeGeneration from '../recipeGeneration/RecipeGeneration';
import { 
  setCreateGeneratedRecipeSelectedMealId,
  setCreateGeneratedRecipeId,
  resetCreateGeneratedRecipeSheet
} from './mealPlanSlice';
import { useAddRecipeToMealMutation, useGetGeneratedRecipeQuery, useListMealsQuery, useGetMealPlanQuery, useRemoveRecipeFromMealMutation, useUpdateMealIdeaMutation } from '../../store/apiSlice';
import { Button } from '../../components/Button';
import { closeRecipeSheet } from '../../store/globalSlice';
import CreateGeneratedRecipeHeader from './CreateGeneratedRecipeHeader';
import { setCurrentPrompt } from '../recipeGeneration/recipeGenerationSlice';

const CreateGeneratedRecipeSheetContent = () => {
  const dispatch = useDispatch();
  const [addRecipeToMeal] = useAddRecipeToMealMutation();
  const [removeRecipeFromMeal] = useRemoveRecipeFromMealMutation();
  const [updateMealIdea] = useUpdateMealIdeaMutation();
  
  const selectedMealId = useSelector(state => 
    state.mealPlan.sheets.createGeneratedRecipe.selectedMealId
  );
  const generatedRecipeId = useSelector(state => 
    state.mealPlan.sheets.createGeneratedRecipe.generatedRecipeId
  );
  const allowSelectionOfMeal = useSelector(state => 
    state.mealPlan.sheets.createGeneratedRecipe.allowSelectionOfMeal
  );
  const currentPrompt = useSelector(state => 
    state.mealPlan.sheets.createGeneratedRecipe.currentPrompt
  );

  const { data } = useGetGeneratedRecipeQuery(generatedRecipeId, {
    skip: !generatedRecipeId,
  });

  // Get current active recipe ID and deprecated IDs from data
  const currentActiveRecipe = data?.recipes 
    ? data.recipes[Math.max(...Object.keys(data.recipes).map(Number))]
    : null;

  const deprecatedRecipeIds = data?.recipes
    ? Object.entries(data.recipes)
        .filter(([version, recipe]) => recipe.recipe_id !== currentActiveRecipe.recipe_id)
        .map(([_, recipe]) => recipe.recipe_id)
    : [];

  const getSelectedMealId = () => selectedMealId;
  
  const handleMealChange = (value) => {
    dispatch(setCreateGeneratedRecipeSelectedMealId(value));
  };

  const handleRecipeIdChange = async (newRecipeId) => {
    dispatch(setCreateGeneratedRecipeId(newRecipeId));
    // If we want to add the generated recipe to the meal automatically, you could do it here:
    // if (selectedMealId && newRecipeId) {
    //   try {
    //     await addRecipeToMeal({
    //       meal_id: selectedMealId,
    //       recipe_id: newRecipeId,
    //       multiplier: 1
    //     });
    //   } catch (error) {
    //     console.error('Failed to add generated recipe to meal:', error);
    //   }
    // }
  };

  
  // Get meal_plan_id from redux state
  const meal_plan_id = useSelector(state => state.mealPlan.meal_plan_id);
  
  // Add meal plan query
  const { data: mealPlan } = useGetMealPlanQuery(meal_plan_id);
  
  // Add meals query
  const { data: mealsData } = useListMealsQuery({ 
    mealPlanId: meal_plan_id 
  });
  
  // Find the selected meal from the meals data
  const selectedMeal = React.useMemo(() => {
    if (!mealsData?.meals || !selectedMealId) return null;
    return mealsData.meals.find(meal => meal.id === selectedMealId);
  }, [mealsData, selectedMealId]);

  // Get meal specific ideas
  const mealSpecificIdeas = React.useMemo(() => {
    if (!mealPlan?.meal_ideas || !selectedMealId) return [];
    return mealPlan.meal_ideas.filter(idea => idea.meal_id === selectedMealId);
  }, [mealPlan, selectedMealId]);


  // Add this new memoized value to check if recipe is already in meal
  const isRecipeAlreadyInMeal = React.useMemo(() => {
    if (!selectedMeal?.recipes || !currentActiveRecipe) return false;
    return selectedMeal.recipes.some(
      recipe => recipe.recipe_id === currentActiveRecipe.recipe_id
    );
  }, [selectedMeal, currentActiveRecipe]);

  // Add this function to handle updating meal ideas with deprecated recipe IDs
  const updateDeprecatedMealIdeas = async () => {

    if (!currentActiveRecipe || !mealSpecificIdeas.length) {
      return;
    }

    const mealIdeasToUpdate = mealSpecificIdeas.filter(idea => 
      idea.recipe_ideas?.some(recipeIdea => 
        recipeIdea.recipe_id && deprecatedRecipeIds.includes(recipeIdea.recipe_id)
      )
    );

    for (const mealIdea of mealIdeasToUpdate) {
      try {
        // Create a new meal idea object with updated recipe ideas
        const updatedMealIdea = {
          ...mealIdea,
          recipe_ideas: mealIdea.recipe_ideas.map(recipeIdea => {
            if (deprecatedRecipeIds.includes(recipeIdea.recipe_id)) {
              // Return new recipe idea object instead of modifying existing one
              return {
                ...recipeIdea,
                recipe_id: currentActiveRecipe.recipe_id,
                recipe: currentActiveRecipe,
                value: currentActiveRecipe.headline
              };
            }
            return recipeIdea;
          })
        };
        await updateMealIdea({
          meal_plan_id: meal_plan_id,
          new_meal_idea: updatedMealIdea
        });
      } catch (error) {
        console.error('Failed to update meal idea:', error);
        console.error('Failed meal idea details:', mealIdea);
      }
    }
  };

  const handleSubmit = async () => {
    if (!selectedMeal || !currentActiveRecipe) {
      return;
    }

    try {
      // First update any meal ideas that reference deprecated recipes
      await updateDeprecatedMealIdeas();

      // Then handle the recipe updates as before
      const deprecatedRecipesToRemove = selectedMeal.recipes
        .filter(recipe => deprecatedRecipeIds.includes(recipe.recipe_id));
      
      for (const recipe of deprecatedRecipesToRemove) {
        await removeRecipeFromMeal({
          meal_id: selectedMealId,
          recipe_id: recipe.recipe_id
        });
      }

      await addRecipeToMeal({
        meal_id: selectedMealId,
        recipe_id: currentActiveRecipe.recipe_id,
        multiplier: 1,
        recipe: currentActiveRecipe
      });

      dispatch(resetCreateGeneratedRecipeSheet());
      dispatch(closeRecipeSheet());

    } catch (error) {
      console.error('Error updating meal recipes:', error);
      console.error('Error details:', {
        error,
        selectedMeal,
        currentActiveRecipe,
        selectedMealId
      });
    }
  };

  // Add this effect to set the initial prompt
  React.useEffect(() => {
    if (currentPrompt) {
      dispatch(setCurrentPrompt(currentPrompt));
    }
  }, [currentPrompt, dispatch]);

  // Add to cleanup effect
  useEffect(() => {
    return () => {
      dispatch(resetCreateGeneratedRecipeSheet());
      dispatch(setCurrentPrompt('')); // Reset the prompt on unmount
    };
  }, [dispatch]);

  return (
    <>
      <SheetHeader>
        <CreateGeneratedRecipeHeader 
          allowSelectionOfMeal={allowSelectionOfMeal}
          getSelectedMealId={getSelectedMealId}
          handleMealChange={handleMealChange}
          handleSubmit={handleSubmit}
          selectedMealId={selectedMealId}
          currentActiveRecipe={currentActiveRecipe}
          isRecipeAlreadyInMeal={isRecipeAlreadyInMeal}
        />
      </SheetHeader>

      <SheetBody className="overflow-hidden flex flex-col">
        <RecipeGeneration 
          generatedRecipeId={generatedRecipeId}
          onRecipeIdChange={handleRecipeIdChange}
          isFullPage={false}
        />
      </SheetBody>
    </>
  );
};

export default CreateGeneratedRecipeSheetContent; 