import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { setSelectedVersion } from './recipeGenerationSlice';

const RecipeVersionNavigation = ({ versions }) => {
    const dispatch = useDispatch();
    const selectedVersion = useSelector((state) => state.recipeGeneration.selectedVersion);
    const maxVersion = versions.length - 1;

    const handlePrevious = () => {
        if (selectedVersion > 0) {
            dispatch(setSelectedVersion(selectedVersion - 1));
        }
    };

    const handleNext = () => {
        if (selectedVersion < maxVersion) {
            dispatch(setSelectedVersion(selectedVersion + 1));
        }
    };

    return (
        <div className="flex items-center justify-left gap-4 mb-4">
            <Button
                variant="darkLink"
                size="sm"
                onClick={handlePrevious}
                disabled={selectedVersion === 0}
            >
                ←
            </Button>
            <span className="text-sm font-medium">
                Version {selectedVersion + 1} of {maxVersion + 1}
            </span>
            <Button
                variant="darkLink"
                size="sm"
                onClick={handleNext}
                disabled={selectedVersion === maxVersion}
            >
                →
            </Button>
        </div>
    );
};

export default RecipeVersionNavigation;
