import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiSlice } from '../../../store/apiSlice';
import { setMealPlanStreaming, clearSelectedMealIdea } from '../mealPlanSlice';
import WebSocketGenerationService from '../../../services/WebSocketGenerationService';

export const useWebSocketGeneration = (mealPlanId, mealsData) => {
    const dispatch = useDispatch();
    const wsService = useRef(null);
    const mealNotes = useSelector(state => state.mealPlan.mealNotes);

    const initiateWebSocketConnection = useCallback((userFeedback) => {
        if (wsService.current) {
            wsService.current.disconnect();
            wsService.current = null;
        }

        wsService.current = new WebSocketGenerationService({
            endpoint: 'generate_meal_ideas',
            payload: {
                meal_plan_id: mealPlanId,
                user_input: userFeedback,
                meal_notes: mealNotes,
                target_meal_ids: mealsData?.meals?.filter(meal => !meal.published && !meal.primary_meal).map(meal => meal.id) || [],
            },
            onOpen: () => {
                dispatch(setMealPlanStreaming(true));
                dispatch(clearSelectedMealIdea());
            },
            onMessage: (data) => {
                dispatch(
                    apiSlice.util.updateQueryData(
                        'getMealPlan',
                        mealPlanId,
                        (draft) => {
                            draft.meal_ideas = data.meal_ideas;
                        }
                    )
                );
            },
            onError: (error) => {
                console.error('WebSocket error:', error);
                dispatch(setMealPlanStreaming(false));
            },
            onClose: () => {
                console.log('WebSocket is closed');
                wsService.current = null;
                dispatch(setMealPlanStreaming(false));
            }
        });

        wsService.current.connect();
    }, [mealPlanId, dispatch, mealNotes, mealsData]);

    return { initiateWebSocketConnection };
}; 