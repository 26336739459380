import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentPrompt: '',
    generatedRecipeId: null,
    recipeChatStreaming: false,
    selectedVersion: 0,
    hasRecipes: false,
    selectedTab: 'chat',
    maxVersion: null,
};

const recipeGenerationSlice = createSlice({
    name: 'recipeGeneration',
    initialState,
    reducers: {
        setCurrentPrompt: (state, action) => {
            state.currentPrompt = action.payload;
        },
        setGeneratedRecipeId: (state, action) => {
            state.generatedRecipeId = action.payload;
        },
        setRecipeChatStreaming: (state, action) => {
            state.recipeChatStreaming = action.payload;
        },
        resetState: () => initialState,
        setSelectedVersion: (state, action) => {
            state.selectedVersion = action.payload;
        },
        setHasRecipes: (state, action) => {
            state.hasRecipes = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setMaxVersion: (state, action) => {
            state.maxVersion = action.payload;
        },
    },
});

export const { setCurrentPrompt, setGeneratedRecipeId, setRecipeChatStreaming, resetState, setSelectedVersion, setHasRecipes, setSelectedTab, setMaxVersion } = recipeGenerationSlice.actions;
export default recipeGenerationSlice.reducer; 