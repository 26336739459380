import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../lib/supabase-client';
import { CALLBACK_URL, SITE_URL } from '../constants';

// New async thunk for sign in
export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: CALLBACK_URL,
        siteDomain: SITE_URL,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    });
    
    if (error) throw error;
  }
);

// New async thunk for sign out
export const signOut = createAsyncThunk(
  'auth/signOut',
  async () => {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  }
);

const initialState = {
  session: null,
  user: null,
  redirectPath: '/schedule',
  loading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signOut.fulfilled, (state) => {
        state.session = null;
        state.user = null;
      });
  },
});

export const { setSession, setUser, setRedirectPath, setLoading } = authSlice.actions;
export default authSlice.reducer; 