import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RecipeGenerationChatUser from './RecipeGenerationChatUser';
import RecipeGenerationChatAssistant from './RecipeGenerationChatAssistant';
import { useGetGeneratedRecipeQuery } from '../../store/apiSlice';
import { setSelectedTab, setSelectedVersion, setMaxVersion } from './recipeGenerationSlice';

const RecipeGenerationResponse = () => {
    const dispatch = useDispatch();
    const generatedRecipeId = useSelector((state) => state.recipeGeneration.generatedRecipeId);
    const maxVersion = useSelector((state) => state.recipeGeneration.maxVersion);
    const { data, error, isLoading } = useGetGeneratedRecipeQuery(generatedRecipeId, {
        skip: !generatedRecipeId,
    });

    useEffect(() => {
        const currentMaxKey = data?.recipes 
            ? Math.max(...Object.keys(data.recipes).map(Number))
            : null;

        // Only switch to recipe tab if we have a new, higher recipe key
        if (currentMaxKey !== null && currentMaxKey > maxVersion) {
            dispatch(setSelectedTab('recipe'));
            dispatch(setSelectedVersion(currentMaxKey));
            dispatch(setMaxVersion(currentMaxKey));
        }
    }, [data?.recipes, dispatch, maxVersion]);

    if (error) return <div>Error loading recipe messages.</div>;
    if (isLoading ||!data || !data?.messages || !generatedRecipeId) return null;

    const messages = data?.messages || [];

    return (
        <div className="p-4 max-w-5xl mx-auto">
            <div className="flex flex-col space-y-6">
                {messages.map((message, index) => (
                    message.role === 'user' ? (
                        <RecipeGenerationChatUser key={index} message={message} />
                    ) : (
                        <RecipeGenerationChatAssistant key={index} message={message} />
                    )
                ))}
            </div>
        </div>
    );
};

export default RecipeGenerationResponse; 