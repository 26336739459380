import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createClient } from '@supabase/supabase-js';
import { BASE_API_URL, SUPABASE_URL, SUPABASE_ANON_KEY } from '../constants';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const session = getState().auth?.session;
      if (session?.access_token) {
        headers.set('Authorization', `Bearer ${session.access_token}`);
      }
      return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    tagTypes: ['RecipeLibrary', 'MealPlan', 'User', 'MealIdea', 'Recipe'],
    endpoints: (builder) => ({
      listRecipeLibrary: builder.query({
        query: () => {
          return {
            url: '/list_recipes',
            method: 'GET',
          }
        },
        transformResponse: (response) => {
          return response.recipes;
        },
        providesTags: ['RecipeLibrary'],
      }),
      deleteRecipe: builder.mutation({
        query: (recipeIds) => ({
          url: '/delete_recipes',
          method: 'DELETE',
          body: recipeIds,
        }),
        async onQueryStarted(recipeIds, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              recipeIds.saved_recipe_ids.forEach((id) => {
                const index = draft.findIndex((r) => r.id === id);
                if (index !== -1) {
                  draft.splice(index, 1);
                }
              });
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      removeRecipeFromQueue: builder.mutation({
        query: (recipeId) => ({
          url: `/remove_saved_recipe_from_queue/${recipeId}`,
          method: 'PUT',
        }),
        async onQueryStarted(recipeId, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              const recipe = draft.find((r) => r.id === recipeId);
              if (recipe) {
                recipe.queued = false;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      addRecipeToQueue: builder.mutation({
        query: (recipeId) => ({
          url: `/add_saved_recipe_to_queue/${recipeId}`,
          method: 'PUT',
        }),
        async onQueryStarted(recipeId, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('listRecipeLibrary', undefined, (draft) => {
              const recipe = draft.find((r) => r.id === recipeId);
              if (recipe) {
                recipe.queued = true;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      listMeals: builder.query({
        query: ({ startDate, endDate, mealPlanId, includeMealPlans = false, includeGroceryLists = false }) => {
          const params = {
            start_date: startDate,
            end_date: endDate,
            include_meal_plans: includeMealPlans,
            include_grocery_lists: includeGroceryLists,
          };
          if (mealPlanId) {
            params.meal_plan_id = mealPlanId;
          }
          return {
            url: '/list_meals',
            params: params,
          };
        },
        transformResponse: (response) => {
          response.meals.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            const mealOrder = ['breakfast', 'lunch', 'dinner', 'snack'];
            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;
            if (mealOrder.indexOf(a.meal_type) < mealOrder.indexOf(b.meal_type)) return -1;
            if (mealOrder.indexOf(a.meal_type) > mealOrder.indexOf(b.meal_type)) return 1;
            return 0;
          });
          return response;
        },
        providesTags: (result) => [
          'MealPlan',
          ...(result?.meals?.map(meal => ({ type: 'Meal', id: meal.id })) || [])
        ],
      }),
      createMeal: builder.mutation({
        query: (mealData) => ({
          url: '/create_meal',
          method: 'POST',
          body: mealData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      removeRecipeFromMeal: builder.mutation({
        query: ({ meal_id, recipe_id }) => ({
          url: '/remove_recipe_from_meal',
          method: 'POST',
          body: { meal_id, recipe_id },
        }),
        invalidatesTags: ['GroceryList'],
        async onQueryStarted({ meal_id, recipe_id }, { dispatch, getState, queryFulfilled }) {
          // Get all matching listMeals queries from the cache that contain this meal
          const queries = apiSlice.util.selectInvalidatedBy(
            getState(),
            [{ type: 'Meal', id: meal_id }]
          );

          // Apply optimistic update to all affected queries
          const patchResults = queries.map(({ endpointName, originalArgs }) => 
            dispatch(
              apiSlice.util.updateQueryData(
                'listMeals',
                originalArgs,
                (draft) => {
                  if (!draft || !draft.meals) {
                    return;
                  }
                  const meal = draft.meals.find(m => m.id === meal_id);
                  if (meal) {
                    meal.recipes = meal.recipes.filter(r => r.recipe_id !== recipe_id);
                  }
                }
              )
            )
          );

          try {
            await queryFulfilled;
          } catch {
            // Undo all optimistic updates on error
            patchResults.forEach(patchResult => patchResult.undo());
          }
        },
      }),
      addRecipeToMeal: builder.mutation({
        query: ({ meal_id, recipe_id, multiplier, recipe }) => ({
          url: '/add_recipe_to_meal',
          method: 'POST',
          body: { meal_id, recipe_id, multiplier },
        }),
        invalidatesTags: ['GroceryList'],
        async onQueryStarted({ meal_id, recipe_id, multiplier, recipe }, { dispatch, getState, queryFulfilled }) {
          if (recipe) {

            const allListMealsQueries = apiSlice.util.selectInvalidatedBy(
              getState(),
              [{ type: 'Meal', id: meal_id }]
            );
            console.log('Found queries:', allListMealsQueries);

            const patchResults = allListMealsQueries.map(({ endpointName, originalArgs }) =>
              dispatch(
                apiSlice.util.updateQueryData(
                  'listMeals',
                  originalArgs,
                  (draft) => {
                    console.log('Full draft structure:', JSON.stringify(draft, null, 2));
                    
                    if (!draft || !draft.meals) {
                      console.log('Draft or draft.meals is undefined');
                      return;
                    }

                    const meal = draft.meals.find(m => m.id === meal_id);
                    console.log('Found meal:', JSON.stringify(meal, null, 2));

                    if (meal) {
                      console.log('Current recipes:', JSON.stringify(meal.recipes, null, 2));
                      const recipeExists = meal.recipes.some(r => r.recipe_id === recipe_id);
                      console.log('Recipe already exists:', recipeExists);

                      if (!recipeExists) {
                        const newRecipe = {
                          recipe_id: recipe_id,
                          multiplier: multiplier || 1,
                          recipe,
                        };
                        console.log('Adding new recipe:', JSON.stringify(newRecipe, null, 2));
                        meal.recipes.push(newRecipe);
                      }
                    }
                  }
                )
              )
            );

            try {
              await queryFulfilled;
              console.log('Query fulfilled successfully');
            } catch (error) {
              console.log('Query failed:', error);
              patchResults.forEach(patchResult => patchResult.undo());
            }
          } else {
            console.log('No recipe provided, skipping optimistic update');
            await queryFulfilled;
          }
        },
      }),
      createGroceryList: builder.mutation({
        query: (groceryListData) => ({
          url: '/create_grocery_list',
          method: 'POST',
          body: groceryListData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      searchRecipesWithParams: builder.query({
        query: ({ searchTerm, maxValues = 15 }) => ({
          url: '/search_for_recipes',
          params: { search_term: searchTerm, max_values: maxValues }
        }),
        transformResponse: (response) => response.recipes,
      }),
      getMealPlan: builder.query({
        query: (mealPlanId) => ({
          url: `/get_meal_plan/${mealPlanId}`,
          method: 'GET'
        }),
        transformResponse: (response) => response.meal_plan,
        providesTags: (result, error, mealPlanId) => [{ type: 'MealPlan', id: mealPlanId }],
      }),
      publishMeals: builder.mutation({
        query: ({ mealIds, published }) => ({
          url: '/set_meal_publish_status',
          method: 'POST',
          body: { meal_ids: mealIds, published },
        }),
        async onQueryStarted({ mealIds, published }, { dispatch, getState, queryFulfilled }) {
          // Get all matching listMeals queries from the cache that contain any of these meals
          const queries = mealIds.flatMap(mealId => 
            apiSlice.util.selectInvalidatedBy(getState(), [{ type: 'Meal', id: mealId }])
          );

          // Remove duplicates since a query might contain multiple affected meals
          const uniqueQueries = [...new Set(queries.map(q => JSON.stringify(q)))].map(q => JSON.parse(q));

          // Apply optimistic update to all affected queries
          const patchResults = uniqueQueries.map(({ endpointName, originalArgs }) => 
            dispatch(
              apiSlice.util.updateQueryData(
                'listMeals',
                originalArgs,
                (draft) => {
                  if (!draft?.meals) return;
                  
                  draft.meals.forEach(meal => {
                    if (mealIds.includes(meal.id)) {
                      meal.published = published;
                    }
                  });
                }
              )
            )
          );

          try {
            await queryFulfilled;
          } catch {
            // Undo all optimistic updates on error
            patchResults.forEach(patchResult => patchResult.undo());
          }
        },
      }),
      getGroceryList: builder.query({
        query: (groceryListId) => ({
          url: `/get_grocery_list/${groceryListId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response.grocery_list,
        providesTags: ['GroceryList'],
      }),
      refreshGroceryList: builder.mutation({
        query: ({ groceryListId }) => ({
          url: `/refresh_grocery_list/${groceryListId}`,
          method: 'POST',
        }),
        invalidatesTags: ['GroceryList'],
      }),
      updateGroceryList: builder.mutation({
        query: ({ groceryListId, groceryList }) => ({
          url: `/save_grocery_list/${groceryListId}`,
          method: 'PUT',
          body: { grocery_list: groceryList },
        }),
        invalidatesTags: ['GroceryList'],
      }),
      getMeal: builder.query({
        query: (mealId) => ({
          url: `/get_meal/${mealId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response.meal,
        providesTags: (result, error, mealId) => [{ type: 'Meal', id: mealId }],
      }),
      addExternalRecipe: builder.mutation({
        query: (recipeData) => ({
          url: '/add_saved_external_recipe',
          method: 'POST',
          body: recipeData,
        }),
        invalidatesTags: ['RecipeLibrary'],
      }),
      validateUrl: builder.mutation({
        query: (url) => ({
          url: '/validate_url',
          method: 'POST',
          body: { url },
        }),
      }),
      getUser: builder.query({
        query: () => ({
          url: '/get_user',
          method: 'GET',
        }),
        transformResponse: (response) => response,
        providesTags: ['User'],
      }),
      createUser: builder.mutation({
        query: () => ({
          url: '/create_user',
          method: 'POST',
        }),
        transformResponse: (response) => response,
        invalidatesTags: ['User'],
      }),
      updateUser: builder.mutation({
        query: (userData) => ({
          url: '/update_user',
          method: 'PUT',
          body: userData,
        }),
        transformResponse: (response) => response,
        invalidatesTags: ['User'],
      }),
      updateRecipeStrings: builder.mutation({
        query: ({ meal_plan_id, meal_idea_id, recipe_strings }) => ({
          url: '/update_recipe_strings',
          method: 'PUT',
          body: { meal_plan_id, meal_idea_id, recipe_strings },
        }),
        invalidatesTags: ['MealPlan'],
      }),
      updateMealIdea: builder.mutation({
        query: ({ meal_plan_id, new_meal_idea }) => {
          const mealIdeaWithoutRecipes = {
            ...new_meal_idea,
            recipe_ideas: new_meal_idea.recipe_ideas?.map(idea => {
              const { recipe, ...ideaWithoutRecipe } = idea;
              return ideaWithoutRecipe;
            })
          };
          return {
            url: '/update_meal_idea',
            method: 'PUT',
            body: { meal_plan_id, new_meal_idea: mealIdeaWithoutRecipes },
          };
        },
        async onQueryStarted({ meal_plan_id, new_meal_idea }, { dispatch, queryFulfilled }) {
          console.log('Starting updateMealIdea mutation', { meal_plan_id, new_meal_idea });
          
          // Apply optimistic update to getMealPlan query
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('getMealPlan', meal_plan_id, (draft) => {
              console.log('Current draft state:', draft);
              
              if (draft?.meal_ideas) {
                console.log('Found meal_ideas in draft');
                const mealIdeaIndex = draft.meal_ideas.findIndex(idea => idea.id === new_meal_idea.id);
                console.log('Found meal idea at index:', mealIdeaIndex);
                
                if (mealIdeaIndex !== -1) {
                  draft.meal_ideas[mealIdeaIndex] = new_meal_idea;
                  
                  console.log('Updated meal idea:', draft.meal_ideas[mealIdeaIndex]);
                } else {
                  console.log('Could not find meal idea with id:', new_meal_idea.id);
                }
              } else {
                console.log('No meal_ideas found in draft');
              }
            })
          );

          try {
            await queryFulfilled;
          } catch {
            // Undo optimistic update if the mutation fails
            patchResult.undo();
          }
        },
      }),
      getHousehold: builder.query({
        query: () => ({
          url: '/get_household',
          method: 'GET',
        }),
        transformResponse: (response) => response.members,
        providesTags: ['Household'],
      }),
      createMealPlan: builder.mutation({
        query: (mealPlanData) => ({
          url: '/create_meal_plan',
          method: 'POST',
          body: mealPlanData,
        }),
        invalidatesTags: ['MealPlan'],
      }),
      clearMealPlanHistory: builder.mutation({
        query: (mealPlanId) => ({
          url: `/clear_meal_plan_history/${mealPlanId}`,
          method: 'POST',
        }),
        invalidatesTags: (result, error, mealPlanId) => [
          { type: 'MealPlan', id: mealPlanId },
          'MealPlan'
        ],
        async onQueryStarted(mealPlanId, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            apiSlice.util.updateQueryData('getMealPlan', mealPlanId, (draft) => {
              if (draft) {
                draft.meal_ideas = [];
                draft.chat_history = [];
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
      getMealIdea: builder.query({
        query: ({ mealPlanId, mealIdeaId }) => ({
          url: `/get_meal_idea/${mealPlanId}/${mealIdeaId}`,
          method: 'GET',
        }),
        transformResponse: (response) => response.meal_idea,
        providesTags: (result, error, { mealPlanId, mealIdeaId }) => [
          { type: 'MealIdea', id: `${mealPlanId}-${mealIdeaId}` }
        ]
      }),
      createRecipe: builder.mutation({
        query: (generatedId) => ({
          url: '/create_recipe',
          method: 'POST',
          body: { generated_recipe_id: generatedId },
        }),
        transformResponse: (response) => response.generated_recipe_id,
        invalidatesTags: ['Recipe', 'RecipeLibrary'],
      }),
      getGeneratedRecipe: builder.query({
        query: (generatedRecipeId) => ({
          url: `/generated_recipe/${generatedRecipeId}`,
          method: 'GET',
        }),
        providesTags: (result, error, generatedRecipeId) => [
          { type: 'Recipe', id: generatedRecipeId }
        ],
      }),
      deduplicateGroceryList: builder.mutation({
        query: (groceryListId) => ({
          url: `/deduplicate_grocery_list/${groceryListId}`,
          method: 'POST',
        }),
        invalidatesTags: ['GroceryList'],
      }),
    })
});

export const {
  useListRecipeLibraryQuery,
  useDeleteRecipeMutation,
  useRemoveRecipeFromQueueMutation,
  useAddRecipeToQueueMutation,
  useListMealsQuery,
  useCreateMealMutation,
  useRemoveRecipeFromMealMutation,
  useAddRecipeToMealMutation,
  useCreateGroceryListMutation,
  useSearchRecipesWithParamsQuery,
  useGetMealPlanQuery,
  usePublishMealsMutation,
  useGetGroceryListQuery,
  useRefreshGroceryListMutation,
  useUpdateGroceryListMutation,
  useGetMealQuery,
  useAddExternalRecipeMutation,
  useValidateUrlMutation,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateRecipeStringsMutation,
  useUpdateMealIdeaMutation,
  useGetHouseholdQuery,
  useCreateMealPlanMutation,
  useClearMealPlanHistoryMutation,
  useGetMealIdeaQuery,
  useCreateRecipeMutation,
  useGetGeneratedRecipeQuery,
  useDeduplicateGroceryListMutation,
} = apiSlice;
