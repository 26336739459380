import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addGroceryListItem } from './groceryListSlice';
import { GroceryListItem } from './GroceryListItem';
import { Button } from '../../components/Button';
import { selectFilteredGroceryList } from './groceryListSlice';

export function GroceryListAisle({ aisle, onAisleNavigation }) {
    const dispatch = useDispatch();
    
    const items = useSelector(state => {
        const filteredList = selectFilteredGroceryList(state);
        return filteredList[aisle] || [];
    });

    const sortOrder = ['unchecked', 'confirm', 'checked'];
    const sortedItems = [...items].sort((a, b) => 
        sortOrder.indexOf(a.checkbox_status) - sortOrder.indexOf(b.checkbox_status)
    );

    const handleAddItem = () => {
        dispatch(addGroceryListItem({ aisle }));
    };

    const handleItemNavigation = (itemId, direction, cursorPosition) => {
        const currentIndex = sortedItems.findIndex(item => item.item_id === itemId);
        
        const tryNavigateUp = () => {
            if (currentIndex > 0) {
                // Navigate to previous item within same aisle
                const prevItem = document.querySelector(`[data-item-id="${sortedItems[currentIndex - 1].item_id}"] input`);
                prevItem?.focus();
                prevItem?.setSelectionRange(cursorPosition, cursorPosition);
                return true;
            } else {
                // Try to navigate to previous aisle
                return onAisleNavigation(aisle, itemId, 'up', cursorPosition);
            }
        };

        const tryNavigateDown = () => {
            if (currentIndex < sortedItems.length - 1) {
                // Navigate to next item within same aisle
                const nextItem = document.querySelector(`[data-item-id="${sortedItems[currentIndex + 1].item_id}"] input`);
                nextItem?.focus();
                nextItem?.setSelectionRange(cursorPosition, cursorPosition);
                return true;
            } else {
                // Try to navigate to next aisle
                return onAisleNavigation(aisle, itemId, 'down', cursorPosition);
            }
        };

        if (direction === 'up') {
            // If we can't navigate up, try navigating down instead
            const succeeded = tryNavigateUp();
            if (!succeeded) {
                tryNavigateDown();
            }
        } else {
            tryNavigateDown();
        }
    };

    return (
        <div className="pb-4">
            <div className="text-xl font-semibold mb-2">
                {aisle.charAt(0).toUpperCase() + aisle.slice(1)}
            </div>
            {sortedItems.map((item) => (
                <div key={item.item_id} data-item-id={item.item_id}>
                    <GroceryListItem 
                        aisle={aisle}
                        itemId={item.item_id}
                        onNavigate={(direction, cursorPosition) => 
                            handleItemNavigation(item.item_id, direction, cursorPosition)
                        }
                    />
                </div>
            ))}
            <Button 
                onClick={handleAddItem} 
                variant="darkLink" 
                size="sm" 
            >
                Add item
            </Button>
        </div>
    );
}
