import { Globe, ChefHat, Library } from 'lucide-react';
import { ControlledToggleableCard } from 'src/components/ToggleableCard';
import { motion } from 'framer-motion';
import { useListRecipeLibraryQuery } from 'src/store/apiSlice';
import { useMemo } from 'react';

const getIdeaDetails = (idea, queuedRecipeIds, recipeNameMap) => {
  switch (idea.function) {
    case 'find_internet_recipe':
      return { Icon: Globe, boldText: idea.value, suffix: ' from the internet' };
    case 'build_simple_ai_recipe':
      return { Icon: ChefHat, boldText: idea.value, suffix: ' built by AI' };
    case 'use_recipe_from_library':
      const isQueued = queuedRecipeIds.includes(idea.value);
      const recipeName = recipeNameMap[idea.value] || 'recipe';
      return { 
        Icon: Library, 
        boldText: recipeName,
        suffix: isQueued ? ' from your queue' : ' from library'
      };
    default:
      return { Icon: Globe, boldText: idea.value || 'Recipe', suffix: '' };
  }
};

const ToggleableMealIdea = ({ mealIdea, isActive, onToggle, disabled }) => {
  const { data: recipes } = useListRecipeLibraryQuery();
  
  // Create recipe name map
  const recipeNameMap = useMemo(() => {
    if (!recipes) return {};
    return recipes.reduce((acc, recipe) => {
      acc[recipe.recipe_id] = recipe.headline;
      return acc;
    }, {});
  }, [recipes]);
  
  // Get all queued recipe IDs
  const queuedRecipeIds = recipes 
    ? recipes.filter(recipe => recipe.queued).map(recipe => recipe.recipe_id)
    : [];

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9, y: -20 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.9, y: -20 }}
      transition={{ duration: 0.4, ease: "easeOut" }}
    >
      <ControlledToggleableCard
        maxWidth="m"
        isActive={isActive}
        onToggle={onToggle}
        disabled={disabled}
        className="mx-1 max-w-xs"
      >
        <div className="flex flex-col gap-1">
          <div className="flex flex-wrap gap-2">
            {mealIdea.recipe_ideas.map((recipeIdea, index) => {
              const { Icon, boldText, suffix = '' } = getIdeaDetails(recipeIdea, queuedRecipeIds, recipeNameMap);
              return (
                <div key={index} className="flex items-start gap-1">
                  <Icon className="h-3 w-3 text-gray-500 flex-shrink-0" />
                  <span className="text-xs text-gray-500 line-clamp-1">
                    <span className="text-black">{boldText}</span>{suffix}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </ControlledToggleableCard>
    </motion.div>
  );
};

export default ToggleableMealIdea;
