'use client'

import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'
import { cn } from 'src/lib/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react' // Assuming you're using lucide-react for icons
import { cva, VariantProps } from 'class-variance-authority'
import { motion } from 'framer-motion'

const Tabs = TabsPrimitive.Root

const tabsListVariants = cva(
  'relative h-12 p-1', // Removed bg-gray-50 and text-black
  {
    variants: {
      variant: {
        default: 'rounded-base',
        header: 'w-full rounded-none',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof tabsListVariants>
>(({ className, variant, ...props }, ref) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)
  const [showLeftArrow, setShowLeftArrow] = React.useState(false)
  const [showRightArrow, setShowRightArrow] = React.useState(false)

  const checkForOverflow = React.useCallback(() => {
    const container = scrollContainerRef.current
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0)
      setShowRightArrow(
        container.scrollWidth > container.clientWidth &&
        container.scrollLeft < container.scrollWidth - container.clientWidth
      )
    }
  }, [])

  React.useEffect(() => {
    checkForOverflow()
    window.addEventListener('resize', checkForOverflow)
    return () => window.removeEventListener('resize', checkForOverflow)
  }, [checkForOverflow])

  const scroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current
    if (container) {
      const scrollAmount = 256 // Adjust this value as needed
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className={cn(tabsListVariants({ variant, className }))}>
      {showLeftArrow && (
        <button
          onClick={() => scroll('left')}
          className="absolute left-1 top-1/2 z-10 -translate-y-1/2 p-1 rounded-full shadow-md bg-white bg-opacity-25"
          aria-label="Scroll left"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
      )}
      <div
        ref={scrollContainerRef}
        className="overflow-x-auto scrollbar-hide h-full"
        onScroll={checkForOverflow}
      >
        <TabsPrimitive.List
          ref={ref}
          className="inline-flex h-full items-center justify-left"
          {...props}
        />
      </div>
      {showRightArrow && (
        <button
          onClick={() => scroll('right')}
          className="absolute right-1 top-1/2 z-10 -translate-y-1/2 p-1 rounded-full shadow-md bg-white bg-opacity-25"
          aria-label="Scroll right"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      )}
    </div>
  )
})
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const [isActive, setIsActive] = React.useState(false);
  const triggerRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    const trigger = triggerRef.current;
    if (trigger) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'data-state') {
            setIsActive(trigger.getAttribute('data-state') === 'active');
          }
        });
      });

      observer.observe(trigger, { attributes: true });
      
      // Initial state
      setIsActive(trigger.getAttribute('data-state') === 'active');

      return () => observer.disconnect();
    }
  }, []);

  const mergedRef = React.useCallback(
    (element: HTMLButtonElement | null) => {
      triggerRef.current = element;
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    },
    [ref]
  );

  return (
    <TabsPrimitive.Trigger
      ref={mergedRef}
      className={cn(
        'inline-flex items-center justify-center whitespace-nowrap py-1.5 text-gray-800 relative',
        'transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2',
        'disabled:pointer-events-none disabled:opacity-50',
        'data-[state=active]:font-bold data-[state=active]:text-black',
        'mr-6',
        className,
      )}
      {...props}
    >
      {props.children}
      {isActive && (
        <motion.div
          layoutId="tab-underline"
          className="absolute bottom-0 left-0 right-0 h-0.5 bg-green"
          transition={{
            type: "spring",
            stiffness: 500,
            damping: 30
          }}
        />
      )}
    </TabsPrimitive.Trigger>
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }