import React from 'react';

const ScheduleGrid = ({ children }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 gap-4">
      {children}
    </div>
  );
};

export default ScheduleGrid;
